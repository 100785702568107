import * as Bananas from "bananas-commerce-admin";

import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";

import { dateStringOrNull } from "../../../utils/dateStringOrNull";
import { OrderStatusBadge } from "../components/OrderStatusBadge";
import { Order } from "../types";

export const OrderRow = ({
  order: {
    id,
    name,
    email,
    case: c,
    purchase_number,
    confirmed_at,
    cancelled_at,
  },
  handleCancelOrder,
  isSuperUser,
}: {
  order: Order;
  handleCancelOrder?: (id: number) => Promise<void>;
  isSuperUser?: boolean;
}) => {
  return (
    <Bananas.NavigatingTableRow
      route="pos.purchase:detail"
      routeParams={{ purchase_number }}
    >
      <Bananas.TableCell>
        <OrderStatusBadge
          type={
            cancelled_at ? "cancelled" : confirmed_at ? "captured" : "created"
          }
        />
      </Bananas.TableCell>
      <Bananas.TableCell>{name}</Bananas.TableCell>
      <Bananas.TableCell>{email}</Bananas.TableCell>
      <Bananas.TableCell>{c}</Bananas.TableCell>
      <Bananas.TableCell>{dateStringOrNull(confirmed_at)}</Bananas.TableCell>
      <Bananas.TableCell align="right" sx={{ pr: 3 }}>
        {purchase_number}
      </Bananas.TableCell>
      {isSuperUser && handleCancelOrder && (
        <Bananas.TableCell align="right">
          <IconButton
            disabled={cancelled_at !== null || confirmed_at !== null}
            onClick={(e) => {
              e.stopPropagation();
              handleCancelOrder(id);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Bananas.TableCell>
      )}
    </Bananas.NavigatingTableRow>
  );
};
