import { memo, useCallback, useEffect, useState } from "react";
import { LabeledValue } from "bananas-commerce-admin";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  SxProps,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";

import LabeledSpinner from "@/components/LabeledSpinner";

import useDeadlinesQuery from "@/extensions/obituary/queries/useDeadlinesQuery";
import { Ad, DayjsDeadline } from "@/extensions/obituary/types";

import { Nullish, Timedate } from "@/utils/types";

const styles: Record<string, SxProps> = {
  spinner: { height: "100%", minHeight: 280 },
};

export interface CreateAdDialogProps
  extends Omit<DialogProps, "onClose" | "onSubmit"> {
  ad: Ad;
  onClose: () => void;
  onSubmit: (publicationDate: Timedate, editingDeadline: Timedate) => void;
}

const CreateAdDialog: React.FC<CreateAdDialogProps> = ({
  ad,
  onClose,
  onSubmit,
  open,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const deadlines = useDeadlinesQuery(
    ad.publication_abbreviation,
    ad.layout_variant,
  );

  const [deadline, setDeadline] = useState<Nullish<DayjsDeadline>>(null);

  useEffect(() => {
    if (deadline != null) return;
    if (deadlines.data == null) return;
    if (ad.publication_date == null) return;

    const publicationDate = dayjs(ad.publication_date);
    setDeadline(
      deadlines.data.find((d) => d.publicationDate.isSame(publicationDate)),
    );
  }, [deadline, setDeadline, deadlines.data]);

  const handleOnChange = useCallback(
    (newDate: Nullish<Dayjs>) => {
      if (newDate == null) return;

      const newDeadline = deadlines.data?.find((d) =>
        newDate.isSame(d.publicationDate),
      );

      if (newDeadline == null) {
        enqueueSnackbar("Det går inte att välja detta datum.", {
          variant: "error",
        });
        return;
      }

      setDeadline(newDeadline);
    },
    [deadlines.data],
  );

  const handleSubmit = useCallback(async () => {
    if (deadline == null) return;
    setIsSubmitting(true);

    onSubmit(
      deadline.publicationDate.format("YYYY-MM-DD"),
      deadline.deadlineTime.toISOString(),
    );

    onClose();
    setIsSubmitting(false);
  }, [deadline, onSubmit]);

  return (
    <Dialog fullWidth open={open} onClose={onClose} {...props}>
      {deadlines.isLoading ? (
        <LabeledSpinner label="Laddar införendedatum…" sx={styles.spinner} />
      ) : isSubmitting ? (
        <LabeledSpinner
          label="Skickar in nytt införendedatum…"
          sx={styles.spinner}
        />
      ) : (
        <>
          <DialogTitle>Välj införandedatum</DialogTitle>

          <DialogContent>
            <Stack gap={2} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <DatePicker
                  format="YYYY-MM-DD"
                  label="Införandedatum"
                  shouldDisableDate={deadlines?.handleShouldDisableDate}
                  value={deadline?.publicationDate}
                  onChange={handleOnChange}
                />
              </FormControl>

              <LabeledValue
                compact
                label="Skickas in senast"
                value={deadline?.deadlineTime.format("YYYY-MM-DD hh:mm") ?? "—"}
              />

              <Stack gap={0}>
                <Divider flexItem />

                <FormHelperText>
                  Det går enbart att välja lediga införandedatum.
                </FormHelperText>
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Avbryt</Button>
            <Button disabled={deadline == null} onClick={handleSubmit}>
              Välj datum
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default memo(CreateAdDialog);
