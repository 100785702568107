import React from "react";
import ReactDOM from "react-dom/client";
import * as Bananas from "bananas-commerce-admin";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import * as Klarahill from "@/extensions/klarahill";
import { Redirect } from "@/extensions/klarahill/components/Redirect";
import * as Obituary from "@/extensions/obituary";

import Logo from "../assets/svg/klarahill-logo.svg?react";
import LogoSymbol from "../assets/svg/klarahill-logo-symbol.svg?react";

// TODO: Replace with luxon
import "dayjs/locale/sv";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <LocalizationProvider adapterLocale="sv" dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <Bananas.App
          api={import.meta.env.API_SCHEMA_URL}
          basename={import.meta.env.BASE_URL}
          contrib={{ ...Obituary.contrib.obituaries }}
          dashboard={Redirect}
          extensions={[
            Bananas.pos.router,
            Bananas.bananas.router,
            Klarahill.orderRouter,
            Klarahill.caseRouter,
            Klarahill.reportRouter,
          ]}
          logo={Logo}
          logoSymbol={LogoSymbol}
          navigation={{
            ...Klarahill.navigation,
          }}
          theme={({ mode }) => {
            const isLightMode = mode === "light";
            const brandMidnight = "#003444";
            const brandMidnightLight = "#0087B0";

            return {
              palette: {
                primary: {
                  main: isLightMode ? brandMidnight : brandMidnightLight,
                },
                secondary: {
                  main: isLightMode ? brandMidnight : brandMidnightLight,
                },
              },

              components: {
                BananasLoginDialog: {
                  styleOverrides: {
                    title: ({ theme }) =>
                      theme.unstable_sx({
                        bgcolor: isLightMode ? "grey.200" : "grey.900",
                      }),
                    backdrop: ({ theme }) =>
                      theme.unstable_sx({
                        bgcolor: brandMidnight,
                      }),
                  },
                },
              },
            };
          }}
        />
      </QueryClientProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);
