import { Divider, DividerProps } from "@mui/material";

export interface ObituaryDividerProps extends Omit<DividerProps, "id"> {
  id: number;
}

export const ObituaryDivider: React.FC<ObituaryDividerProps> = ({
  id,
  ...props
}) => {
  return <Divider {...props} key={`div-${id}`} sx={{ my: 0.5, ...props.sx }} />;
};
