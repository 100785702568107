import React from "react";

import { Box, BoxProps, SxProps, Theme, Typography } from "@mui/material";

const styles: Record<string, SxProps<Theme>> = {
  root: {
    display: "inline-flex",
    py: 0.75,
    pl: 1.75,
    pr: 2,
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    borderRadius: 100,
    "> svg": {
      width: 18,
      height: 18,
    },
  },

  label: {
    fontSize: 12,
    lineHeight: 0,
    fontWeight: 600,
    letterSpacing: 1.25,
    textTransform: "uppercase",
    textAlign: "center",
  },
};

export interface BadgeProps extends BoxProps {
  icon: React.ReactNode;
  label?: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

const Badge: React.FC<BadgeProps> = ({
  icon,
  label,
  backgroundColor = "#BBDEFB",
  foregroundColor = "#1976D2",
  sx,
  ...props
}) => (
  // @ts-expect-error - Incorrect sx type
  <Box
    {...props}
    sx={{
      bgcolor: backgroundColor,
      color: foregroundColor,
      ...styles.root,
      ...sx,
    }}
  >
    {icon}
    {label != null && <Typography sx={styles.label}>{label}</Typography>}
  </Box>
);

export default Badge;
