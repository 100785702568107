import { useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Typography } from "@mui/material";

import * as styles from "@/extensions/klarahill/pages/case/shared";

import ObituaryCard from "./ObituaryCard";
import { PresentationCard } from "./PresentationCard";
import { SettingsCard } from "./SettingsCard";

export const Memorial: React.FC<styles.CaseTabProps> = ({ case: c }) => {
  const [memorial, setMemorial] = useState(c.memorial);

  return (
    <Bananas.Content layout="fixedWidth">
      <Bananas.LeftColumn>
        <PresentationCard
          case={c}
          memorial={memorial}
          setMemorial={setMemorial}
        />

        <ObituaryCard memorial={memorial} setMemorial={setMemorial} />

        <Typography sx={{ color: "grey.600" }} variant="body2">
          Information om begravningsärendet hämtas från Bitnet. Gå till Bitnet
          för att kunna ändra någon inställningar för begravnings, minnestund
          eller gåva.
        </Typography>
      </Bananas.LeftColumn>

      <Bananas.RightColumn>
        <SettingsCard memorial={memorial} setMemorial={setMemorial} />
      </Bananas.RightColumn>
    </Bananas.Content>
  );
};
