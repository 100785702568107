import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

import { Gift } from "../types";

import Badge from "./Badge";

const statusIcon: Record<Gift["status"], React.FC<{ fontSize: "small" }>> = {
  executed: AddCardOutlinedIcon,
  captured: CreditScoreOutlinedIcon,
  disbursed: CreditScoreOutlinedIcon,
  final_failure: BlockOutlinedIcon,
  cancelled: BlockOutlinedIcon,
  halted: BlockOutlinedIcon,
  refunded: ReplayOutlinedIcon,
} as const;

const statusColor: Record<Gift["status"], Readonly<[string, string]>> = {
  executed: ["#BBDEFB", "#1976D2"],
  captured: ["#C8E6C9", "#1B5E20"],
  disbursed: ["#C8E6C9", "#1B5E20"],
  final_failure: ["#FFE0B2", "#E65100"],
  cancelled: ["#FFE0B2", "#E65100"],
  halted: ["#FFE0B2", "#E65100"],
  refunded: ["#E5E5E5", "#525252"],
} as const;

const statusLabel: Record<Gift["status"], string> = {
  executed: "Påbörjad",
  captured: "Betald",
  disbursed: "Utbetald",
  final_failure: "Avbruten",
  cancelled: "Avbruten",
  halted: "Avbruten",
  refunded: "Återbetald",
} as const;

export const GiftStatusBadge = ({ type }: { type: Gift["status"] }) => {
  const [background, foreground] = statusColor[type];
  const label = statusLabel[type];
  const Icon = statusIcon[type];

  return (
    <Badge
      backgroundColor={background}
      foregroundColor={foreground}
      icon={<Icon fontSize="small" />}
      label={label}
      sx={{ minWidth: 150 }}
    />
  );
};
