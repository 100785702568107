import { get } from "typedash/get";
import { set } from "typedash/set";

import { Item, Story } from "./Story";

import * as object from "@/utils/object";
import { Nullish } from "@/utils/types";

export type Direction = "up" | "down";

export function assertDirection(
  direction: unknown,
): asserts direction is Direction {
  if (direction !== "up" && direction !== "down")
    throw new Error("Invalid direction");
}

export interface BaseAction {
  type: string;
  path: object.Path;
}

export interface Add<T extends Item> extends BaseAction {
  type: "add";
  direction: Direction;
  payload: T;
}

export interface Change<T extends Item> extends BaseAction {
  type: "change";
  payload: Partial<T>;
}

export interface Move extends BaseAction {
  type: "move";
  direction: Direction;
}

export interface Swap extends BaseAction {
  type: "swap";
  target: object.Path;
}

export interface Remove extends BaseAction {
  type: "remove";
}

export interface Replace extends Omit<BaseAction, "path"> {
  type: "replace";
  story: Story;
}

export type Action<T extends Item> =
  | Add<T>
  | Change<T>
  | Move
  | Swap
  | Remove
  | Replace;

// Utils

function parsePathWithIndex(path: object.Path): [number, string, object.Path] {
  const newPath = [...path];

  let index = newPath.pop();
  if (index == null) throw new Error("Missing index");
  if (typeof index === "string") index = Number.parseInt(index);
  const parentPath = newPath.join(".");

  return [index, parentPath, newPath];
}

function getInStory(state: Story, path: string, index?: number) {
  if (path === "") {
    if (index == null) return state;
    return state[index] as Nullish<Item>;
  }

  if (index == null) return get(state, path);

  const parent = get(state, path) as Item[];
  return parent[index];
}

function setInStory(state: Story, path: string, index: number, item: Item) {
  if (path === "") return state.toSpliced(index, 1, item);

  const parent = get(state, path) as Item[];
  const result = parent.toSpliced(index, 0, item);

  // @ts-expect-error - Dynamic path
  set(state, path, result);
  return state;
}

function unset(state: Story, path: string, index: number): Story {
  if (path === "") return state.toSpliced(index, 1);
  if (index < 0) return state;

  const parent = get(state, path) as Nullish<Item[]>;
  if (parent == null) {
    console.error("Invalid unset, parent is null");
    return state;
  }

  const result = parent.toSpliced(index, 1);
  // @ts-expect-error - Dynamic path
  set(state, path, result);
  return state;
}

// Reducer

function reducer(state: Story, action: Action<Item>) {
  switch (action.type) {
    case "add": {
      assertDirection(action.direction);
      const distance = action.direction === "up" ? -1 : 1;

      const newState = globalThis.structuredClone(state);
      const [index, parentPath] = parsePathWithIndex(action.path);
      const target = Math.max(index + distance, 0);

      const isRoot = parentPath === "";
      const parent = getInStory(newState, parentPath) as Nullish<Item[]>;
      if (parent == null) {
        console.error("Invalid add, parent is null");
        return state;
      }

      const payloadWithId =
        "id" in action.payload
          ? action.payload
          : { ...(action.payload as Item), id: crypto.randomUUID() };

      const item = parent.toSpliced(target, 0, payloadWithId);

      if (isRoot) {
        return item;
      } else {
        // @ts-expect-error - Dynamic path
        set(newState, parentPath, item);
        return newState;
      }
    }

    case "change": {
      const newState = globalThis.structuredClone(state); // TODO: Polyfill
      const path = action.path.join(".");

      const value = getInStory(state, path);
      if (value == null) {
        console.error("Invalid change, value is null");
        return state;
      }

      const result = Object.assign({}, value, action.payload);

      // @ts-expect-error - Dynamic path
      set(newState, path, result);
      return newState as Story;
    }

    case "move": {
      assertDirection(action.direction);
      const distance = action.direction === "up" ? -1 : 1;

      const newState = globalThis.structuredClone(state);
      const [index, parentPath] = parsePathWithIndex(action.path);

      const isRoot = parentPath === "";

      const item = isRoot
        ? newState[index]
        : (get(newState, action.path.join(".")) as Item);
      const parent = isRoot ? newState : (get(newState, parentPath) as Item[]);

      // Don't move up if the item is already at the edge.
      if (index + distance < 0) return state;

      // Don't move down if the item is already at the edge.
      if (index + distance >= parent.length) return state;

      if (item == null) {
        console.error("Invalid move, item is null");
        return state;
      }

      return setInStory(
        unset(newState, parentPath, index),
        parentPath,
        index + distance,
        item,
      );
    }

    case "swap": {
      const newState = globalThis.structuredClone(state);
      const [index, parentPath] = parsePathWithIndex(action.path);
      const [targetIndex, targetParentPath] = parsePathWithIndex(action.target);
      if (index === targetIndex && parentPath === targetParentPath)
        return state;

      const isRoot = parentPath === "";
      const item = isRoot
        ? newState[index]
        : (get(newState, parentPath) as Item);
      const target = isRoot
        ? newState[targetIndex]
        : (get(newState, targetParentPath) as Item);

      if (item == null || target == null) {
        console.error("Invalid swap, item or target is null");
        return state;
      }

      const withoutItem = unset(newState, parentPath, index);
      const withoutTarget = unset(withoutItem, targetParentPath, targetIndex);
      const withItem = setInStory(
        withoutTarget,
        targetParentPath,
        targetIndex,
        item,
      );
      const withTarget = setInStory(withItem, parentPath, index, target);
      return withTarget;
    }

    case "remove": {
      const newState = globalThis.structuredClone(state);
      const [index, parentPath] = parsePathWithIndex(action.path);
      return unset(newState, parentPath, index);
    }

    case "replace": {
      return action.story;
    }

    default:
      throw new Error("Invalid action");
  }
}

export default reducer;
