import { memo, useCallback, useMemo, useState } from "react";

import { Stack, SxProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";

import TextField from "@/components/TextField";
import emoticons from "@/components/TextField/emoticons";

import {
  DEBOUNCE,
  Editable,
  Item,
} from "@/extensions/obituary/pages/detail/Story/shared";

import { useStory } from "../context";

import EditableItem from "./EditableItem";

import { Nullish, Option } from "@/utils/types";

export interface Date extends Item {
  type: "date";
  variant: "birth-date" | "deceased-date";
  date: string;
  icon?: string;
  prefix?: string;
  suffix?: string;
}

export function isDate(item: Item): item is Date {
  return item.type === "date";
}

export const variants: Option[] = [
  { value: "birth-date", label: "Född" },
  { value: "deceased-date", label: "Avliden" },
];

export function isVariant(value: unknown): value is Date["variant"] {
  return variants.some((option) => option.value === value);
}

const styles: Record<string, SxProps> = {
  input: {
    bgcolor: "white",
    width: "100%",
  },
  row: {
    "& > *": { flex: 1 },
  },
};

export const Edit: Editable<Date> = ({
  item: { date: initialDate, hidden, icon, variant, prefix, suffix },
  path,
}) => {
  const { change } = useStory();

  const [date, setDate] = useState(dayjs(initialDate));

  const handleChangeDate = useCallback(
    (date: Nullish<Dayjs>) => {
      if (date == null) return;
      setDate(date);
      change<Date>(path, { date: date.format("YYYY-MM-DD") });
    },
    [change],
  );

  const handleChangeData = useCallback(
    (key: keyof Date) => (value: string) =>
      change<Date>(path, { [key]: value }),
    [change],
  );

  const title = useMemo(() => {
    let title = variants.find((option) => option.value === variant)?.label;
    if (hidden) title = `${title} (Dold)`;
    return title ?? "Datum";
  }, [hidden, variant]);

  return (
    <EditableItem hidden={hidden} path={path} title={title}>
      {!hidden && (
        <Stack gap={2}>
          <Stack direction="row" gap={2} sx={styles.row}>
            <DatePicker
              format="YYYY-MM-DD"
              sx={styles.input}
              value={date}
              onChange={handleChangeDate}
            />

            <TextField
              debounce={DEBOUNCE}
              emoticons={emoticons}
              label="Ikon"
              name="icon"
              sx={styles.input}
              value={icon}
              variant="outlined"
              onChange={handleChangeData("icon")}
            />
          </Stack>

          <Stack direction="row" gap={2} sx={styles.row}>
            <TextField
              debounce={DEBOUNCE}
              label="Prefix"
              name="prefix"
              size="small"
              sx={styles.input}
              value={prefix}
              variant="outlined"
              onChange={handleChangeData("prefix")}
            />

            <TextField
              debounce={DEBOUNCE}
              label="Suffix"
              name="suffix"
              size="small"
              sx={styles.input}
              value={suffix}
              variant="outlined"
              onChange={handleChangeData("suffix")}
            />
          </Stack>
        </Stack>
      )}
    </EditableItem>
  );
};

export default memo(Edit);
