import { memo } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Message } from "@/extensions/klarahill/types";

export interface MessagesCardProps extends Bananas.CardProps {
  messages: Message[];
  removedMessages: Message[];
}

export const MessagesCard: React.FC<MessagesCardProps> = ({
  messages,
  removedMessages,
}) => (
  <Bananas.Card isCompact>
    <Bananas.CardHeader title="Minnen" />

    <Bananas.CardContent>
      <Bananas.CardRow>
        <Bananas.CardFieldNumber
          formName="shown"
          label="Synliga"
          value={messages.length - removedMessages.length}
        />
      </Bananas.CardRow>

      <Bananas.CardRow>
        <Bananas.CardFieldNumber
          formName="hidden"
          label="Dolda"
          value={removedMessages.length}
        />
      </Bananas.CardRow>
    </Bananas.CardContent>
  </Bananas.Card>
);

export default memo(MessagesCard);
