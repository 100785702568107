import { useMemo } from "react";
import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import { useSnackbar } from "notistack";

import { Case, Cause } from "@/extensions/klarahill/types";

export interface CausesCardProps extends Bananas.CardProps {
  case: Case;
  setCase: React.Dispatch<React.SetStateAction<Case>>;
}

export interface CausesCardFromValues {
  causes: string[];
}

export const CausesCard: React.FC<CausesCardProps> = ({
  case: c,
  setCase,
  ...props
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const { data: pennybridgeCauses } = useQuery({
    queryKey: ["pennybridge", "cause", "list"],
    queryFn: async () => {
      const action = api.operations["pennybridge.cause:list"];
      if (!action) throw new Error('Invalid action "pennybridge.cause:list".');

      const response = await action.call();

      if (response.ok) {
        return (await response.json()) as Cause[];
      } else {
        enqueueSnackbar("Kunde inte hämta gåvoorganisationer.", {
          variant: "error",
        });
        return [];
      }
    },
  });

  const options =
    pennybridgeCauses?.map(({ name, id }) => ({ label: name, id })) ?? [];

  const value = useMemo(() => {
    return c.causes?.map(({ name, id }) => ({ label: name, id })) ?? [];
  }, [c.causes]);

  return (
    <Bananas.Card<CausesCardFromValues>
      isCompact
      isEditable
      onSubmit={async (_, event) => {
        const form = event.currentTarget;
        const formData = new FormData(form);
        const causes = formData.getAll("causes");
        if (causes == null || causes.length === 0)
          return "Ingen förändring av mottagre.";

        const action = api.operations["case.case:update"];
        if (!action) throw new Error('Invalid action "case.case:update".');

        const response = await action.call({
          params: { case_id: c.id },
          body: { cause_ids: causes }, // TODO: Check that we get the right thing (the id)
        });

        if (response.ok) {
          const data: Case = await response.json();
          setCase(data);
          return "Minnesgåvomottagare ändrade.";
        } else {
          throw response;
        }
      }}
      {...props}
    >
      <Bananas.CardHeader title="Minnesgåva" />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldAutoComplete
            fallback="Standardmottagare"
            formName="causes"
            label="Mottagare"
            options={options}
            value={value}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions>
        <Bananas.CardCancelButton />
        <Bananas.CardSaveButton />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};
