import { memo } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";

import { Option } from "@/utils/types";

export type SelectProps = MuiSelectProps & {
  label?: string;
  options: Option[];
  tooltip?: string;
};

const Select: React.FC<SelectProps> = ({
  options,
  label,
  sx,
  tooltip,
  ...props
}) => {
  const hasOptions = !Array.isArray(options) || options.length === 0;
  const hasTooltip = tooltip != null && tooltip.length > 0;

  return (
    <FormControl fullWidth>
      {label != null && <InputLabel>{label}</InputLabel>}

      <MuiSelect
        label={label}
        sx={{
          bgcolor: "background.paper",
          cursor: hasOptions ? "not-allowed" : "pointer",
          ...sx,
        }}
        {...props}
      >
        {options?.map(({ disabled, label, value }, i) => (
          // @ts-expect-error - TODO: Fix when returning to Obituary Editor
          <MenuItem key={i} disabled={disabled} value={value}>
            {disabled ? `${label} (ej tillgänglig)` : label}
          </MenuItem>
        ))}
      </MuiSelect>

      {hasTooltip && <FormHelperText>{tooltip}</FormHelperText>}
    </FormControl>
  );
};

export default memo(Select);
