import React, { memo, useCallback } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import RemoveBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  BoxProps,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { Item } from "@/extensions/obituary/pages/detail/Story/shared";

import { Direction } from "../../reducer";
import { useStory } from "../context";

import Toolbar from "./Toolbar";
import ToolbarButton from "./ToolbarButton";

import * as object from "@/utils/object";
import { Nullish, Option } from "@/utils/types";

const MAX_SPLITS = 3;

const styles: Record<string, SxProps> = {
  root: {
    px: 2,
    "&:hover menu": { opacity: 1 },
  },

  header: {
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontWeight: "600",
    color: "text.secondary",
    textTransform: "uppercase",
    letterSpacing: 0.2,
    ml: 0.5,
  },

  toolbar: {
    opacity: 0,
    transition: "opacity 100ms",
  },

  // @ts-expect-error - Incorrect sx props
  select: {
    fontFamily: "inherit",
    border: "unset !important",
    bgcolor: "transparent",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    outline: "none",
    borderRadius: "4px",
    transition: "background-color 100ms",
    p: "2px 6px",
    m: "-2px -6px",

    "&:hover": {
      cursor: "pointer",
      bgcolor: (theme: Theme) => theme.palette.action.hover,
    },
  },
};

export interface EditableItemProps extends Pick<Item, "hidden">, BoxProps {
  canBeSplit?: boolean;
  children: React.ReactNode;
  actions?: "mutate" | "hide";
  title?: string;
  path: object.Path;
  variant?: string;
  variants?: Option[];
  onVariantChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onSplit?: Nullish<(split: -1 | 1) => void>;
  splits?: number;
}

const EditableItem: React.FC<EditableItemProps> = ({
  actions,
  children,
  hidden,
  onSplit,
  onVariantChange,
  path,
  splits,
  sx,
  title,
  variant,
  variants,
  ...props
}) => {
  actions ??= "hide";

  const { change, dispatch } = useStory();

  const hasVariants =
    variant != null && variants != null && onVariantChange != null;

  const handleRemove = useCallback(
    () => dispatch({ type: "remove", path }),
    [dispatch, path],
  );

  const handleMove = useCallback(
    (direction: Direction) => () => dispatch({ type: "move", path, direction }),
    [dispatch, path],
  );

  const handleHide = useCallback(
    () => change<Item>(path, { hidden: !hidden }),
    [dispatch, hidden, path],
  );

  const handleSplit = useCallback(
    (split: -1 | 1) => () => onSplit?.(split),
    [onSplit],
  );

  return (
    // @ts-expect-error - Incorrect sx props
    <Stack
      component="article"
      gap={0.25}
      sx={{ ...styles.root, ...(hidden && { mb: -2 }) }}
      {...props}
    >
      {title != null && path != null && (
        <Stack component="header" direction="row" gap={1} sx={styles.header}>
          {hasVariants ? (
            <Box
              component="select"
              // @ts-expect-error - Incorrect sx prop
              sx={{ ...styles.title, ...styles.select }}
              tabIndex={-1}
              value={variant}
              onChange={onVariantChange}
            >
              {variants.map(({ value, label }, i) => (
                // @ts-expect-error - TODO: Fix when returning to obituary editor
                <option key={i} value={value}>
                  {label}
                </option>
              ))}
            </Box>
          ) : (
            <Typography sx={styles.title} variant="caption">
              {title}
            </Typography>
          )}

          <Toolbar
            className="toolbar"
            sx={{ ...styles.toolbar, ...(hidden && { opacity: 1 }) }}
          >
            {onSplit != null && (
              <>
                <ToolbarButton
                  disabled={splits == null || splits <= 1}
                  icon={<RemoveBoxIcon />}
                  tooltip="Ta bort sista spalten"
                  onClick={handleSplit(-1)}
                />

                <ToolbarButton
                  disabled={(splits ?? 1) >= MAX_SPLITS}
                  icon={<AddBoxIcon />}
                  sx={{ ml: -1, mr: 1 }}
                  tooltip="Lägg till spalt till höger"
                  onClick={handleSplit(1)}
                />
              </>
            )}

            {actions === "mutate" ? (
              <>
                <ToolbarButton
                  icon={<ArrowUpwardIcon />}
                  tooltip="Flytta upp"
                  onClick={handleMove("up")}
                />

                <ToolbarButton
                  icon={<ArrowDownwardIcon />}
                  sx={{ ml: -1 }}
                  tooltip="Flytta ner"
                  onClick={handleMove("down")}
                />

                <ToolbarButton
                  icon={<CloseIcon />}
                  tooltip="Ta bort"
                  onClick={handleRemove}
                />
              </>
            ) : (
              <ToolbarButton
                icon={hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                tooltip={hidden ? "Visa" : "Dölj"}
                onClick={handleHide}
              />
            )}
          </Toolbar>
        </Stack>
      )}

      {children}
    </Stack>
  );
};

export default memo(EditableItem);
