import React, { memo, useCallback, useEffect, useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import { SxProps } from "@mui/material";

import { useSnackbar } from "notistack";

import LabeledSpinner from "@/components/LabeledSpinner";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import useCaseQuery from "@/extensions/klarahill/queries/caseQuery";
import { Case } from "@/extensions/klarahill/types";
import useAdsQuery from "@/extensions/obituary/queries/useAdsQuery";
import { Ad, UpdateAdPayload } from "@/extensions/obituary/types";

import useDebounce from "@/hooks/useDebounce";

import Editor from "./Editor";
import { Story } from "./Story";

import * as params from "@/utils/searchParams";
import { Nullish } from "@/utils/types";

const styles: Record<string, SxProps> = {
  spinner: { width: "100%", height: "100%" },
};

export interface ObituaryDataLoaderProps {
  query: {
    case_id: Case["id"];
    ad_id: Ad["id"];
  };
}

const ObituaryDetail: React.FC<ObituaryDataLoaderProps> = ({
  query: { case_id, ad_id },
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const c = useCaseQuery(case_id);
  const ads = useAdsQuery(case_id);
  const [ad, setAd] = useState<Nullish<Ad>>();

  const handleUpdateAd = useCallback(
    async ({
      ad,
      story,
      payload: partialPayload,
    }: {
      ad: Nullish<Ad>;
      story: Nullish<Story>;
      payload?: Nullish<Partial<UpdateAdPayload>>;
    }) => {
      if (ad == null || story == null) {
        console.error("Missing ad or story", ad, story);
        return;
      }

      const action = api.operations["obituary.ad:update"];
      if (!action) throw new Error('Invalid action "obituary.ad:update"');

      const payload: Partial<UpdateAdPayload> = {
        ...partialPayload,
        document: {
          publication: ad.publication_abbreviation,
          layout: ad.layout_variant,
          story,
        },
      };

      const response = await action.call({
        params: { obituary_ad_id: ad.id },
        body: payload,
      });

      if (response.ok) {
        const newAd: Ad = await response.json();
        setAd(newAd);
      } else {
        enqueueSnackbar(
          "Fel vid uppdatering av annons. Försök igen eller kontakta support om felet kvarstår.",
          { variant: "error" },
        );
      }
    },
    [api],
  );

  const debouncedHandleAdUpdate = useDebounce(handleUpdateAd, 1000);

  const handleGetAd = useCallback(
    async (adId: Ad["id"]) => {
      if (Number.isNaN(adId)) return null;

      const action = api.operations["obituary.ad:retrieve"];
      if (!action)
        throw new Error('No action found for "obituary.ad:retrieve"');

      const response = await action.call({
        params: { obituary_ad_id: adId },
      });

      if (response.ok) {
        const ad: Ad = await response.json();
        return ad;
      } else {
        enqueueSnackbar(`Kunde inte hämta annons med id ${adId}.`, {
          variant: "error",
        });
        return null;
      }
    },
    [api, enqueueSnackbar],
  );

  const handleSetAd = useCallback(async (adId: Ad["id"]) => {
    setAd(null);

    const newAds = await ads.refetch();
    if (newAds.isSuccess) {
      const ad = newAds.data?.find((a) => a.id === adId);
      if (ad != null) {
        params.setManually("adId", ad.id.toString());
        params.setManually("tab", "obituary.ad:list");
        setAd(ad);
      }
    } else {
      enqueueSnackbar("Kunde inte hämta annonser.", { variant: "error" });
    }
  }, []);

  useEffect(() => {
    const getInitialAd = async () => {
      const ad = await handleGetAd(ad_id);
      if (ad != null) setAd(ad);
    };
    if (ad_id != null && ad == null) getInitialAd();
  }, [ad_id, handleGetAd]);

  return c.isLoading || ads.isLoading || ad == null ? (
    <LabeledSpinner label="Laddar annons…" sx={styles.spinner} />
  ) : c.isSuccess && ads.isSuccess && ad != null ? (
    <Editor
      key={ad.id}
      ad={ad}
      ads={ads.data!}
      c={c.data!}
      setAd={handleSetAd}
      updateAd={debouncedHandleAdUpdate}
    />
  ) : (
    <ErrorCard errorMessage="Ett fel inträffade, kunde inte hämta begravningsannons. Försök igen senare eller kontakta support om problemet kvarstår." />
  );
};

export default memo(ObituaryDetail);
