import React from "react";
import * as Bananas from "bananas-commerce-admin";

import { DateTime } from "luxon";
import { useSnackbar } from "notistack";

export interface ExportFloristOrdersCardProps extends Bananas.CardProps {}

export interface ExportFloristOrdersCardFormValues {
  startDate: string;
  endDate: string;
}

export const ExportFloristOrdersCard: React.FC<
  ExportFloristOrdersCardProps
> = () => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Bananas.Card<ExportFloristOrdersCardFormValues>
      alwaysEditable
      onSubmit={async (values) => {
        const action = api.operations["report.order:create"];
        if (!action || action.server == null || action.endpoint == null)
          throw new Error('Invalid action "report.order:create".');

        const startDate = DateTime.fromFormat(
          values.startDate,
          "MM/dd/yyyy",
        ).toISODate();
        if (startDate == null) {
          enqueueSnackbar("Felaktigt startdatum", { variant: "error" });
          return;
        }

        const endDate = DateTime.fromFormat(
          values.endDate,
          "MM/dd/yyyy",
        ).toISODate();
        if (endDate == null) {
          enqueueSnackbar("Felaktigt startdatum", { variant: "error" });
          return;
        }

        const url = new URL(action.server + action.endpoint);
        url.searchParams.set("start_date", startDate);
        url.searchParams.set("end_date", endDate);

        const a = document.createElement("a");
        a.href = url.toString();
        a.click();
        a.remove();

        return "Rapporten har laddats ned.";
      }}
    >
      <Bananas.CardHeader
        subheader="Generera och ladda ner en rapport över alla bekräftade köp mellan de två valda datumen."
        title="Exportera blomsterordrar"
      />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldDate
            required
            formName="startDate"
            label="Från"
            value={DateTime.now().startOf("month")}
          />

          <Bananas.CardFieldDate
            required
            formName="endDate"
            label="Till"
            value={DateTime.now()}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions alwaysVisible>
        <Bananas.CardSaveButton label="Ladda ned rapport" />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};

export default ExportFloristOrdersCard;
