import React, { memo, useCallback } from "react";

import { Box, SxProps, Theme, Typography } from "@mui/material";

import { Option } from "@/utils/types";

export interface TabProps {
  active: boolean;
  onClick: (tab: Option) => void;
  tab: Option;
}

export const styles: Record<string, SxProps<Theme>> = {
  tab: {
    cursor: "pointer",
    px: 1,
    py: 0.5,
    backgroundColor: (theme: Theme) => theme.palette.grey[200],
    border: (theme: Theme) => `1px solid ${theme.palette.grey[300]}`,
    color: (theme: Theme) => theme.palette.grey[800],
    borderRadius: 1,
    height: 32,
    userSelect: "none",

    transition: "background-color 200ms",

    "&:hover": {
      borderColor: (theme: Theme) => theme.palette.grey[600],
    },
  },

  active: {
    backgroundColor: "background.paper",
    color: "black",
    cursor: "default",
  },

  label: {
    maxWidth: 240,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

const Tab: React.FC<TabProps> = ({ active, onClick, tab }) => {
  const handleOnClick = useCallback(() => {
    if (!active) onClick(tab);
  }, [active, onClick]);

  return (
    <Box
      role="button"
      sx={{ ...styles.tab, ...(active && styles.active) } as SxProps}
      onClick={handleOnClick}
    >
      <Typography sx={styles.label} variant="subtitle2">
        {tab.label}
      </Typography>
    </Box>
  );
};

export default memo(Tab);
