import { useMemo } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Stack } from "@mui/material";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import * as styles from "@/extensions/klarahill/pages/case/shared";

import MessageCard from "./Message";
import MessagesCard from "./MessagesCard";

export const Messages: React.FC<styles.CaseTabProps> = ({
  case: {
    memorial: { messages },
  },
}) => {
  const removedMessages = useMemo(
    () => messages.filter((message) => message?.status === "removed"),
    [messages],
  );

  const hasMessages = useMemo(
    () => Array.isArray(messages) && messages.length > 0,
    [messages],
  );

  return (
    <Bananas.Content layout="fixedWidth">
      <Bananas.LeftColumn>
        {hasMessages ? (
          <Stack gap={1}>
            {messages.map((message) => (
              <MessageCard key={message.id} initialMessage={message} />
            ))}
          </Stack>
        ) : (
          <ErrorCard errorMessage="Det finns inga minnen för det här minnesrummet." />
        )}
      </Bananas.LeftColumn>

      <Bananas.RightColumn>
        <MessagesCard messages={messages} removedMessages={removedMessages} />
      </Bananas.RightColumn>
    </Bananas.Content>
  );
};
