import React, { memo, useCallback, useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  IconButton,
  SxProps,
  Theme,
  Toolbar,
  ToolbarProps,
  Typography,
} from "@mui/material";

const ZoomSx: SxProps = {
  bgcolor: "background.paper",
  border: "1px solid",
  borderColor: "grey.300",
  borderRadius: 1,
  p: "2px !important",
  minHeight: "auto !important",
  "&:hover": { borderColor: "grey.600" },
};

const typographySx: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.grey[800],
};

interface ZoomProps extends Omit<ToolbarProps, "onChange"> {
  increment?: number;
  zoom?: number;
  onChange?: (zoom: number) => void;
  min?: number;
  max?: number;
}

const Zoom: React.FC<ZoomProps> = ({
  zoom = 1,
  increment = 0.5,
  onChange,
  min = -Infinity,
  max = Infinity,
  sx: passedSx,
  ...props
}) => {
  if (zoom < min || zoom > max) {
    throw new Error("defaultZoom must be between min and max");
  }

  const handleZoomChange = useCallback(
    (change: number) => () => {
      let newZoom = Math.max(increment, zoom + change); // Ensure zoom doesn't go below 0.1
      if (min) newZoom = Math.max(min, newZoom);
      if (max) newZoom = Math.min(max, newZoom);
      onChange?.(newZoom);
    },
    [zoom, onChange],
  );

  const sx = useMemo(() => ({ ...ZoomSx, ...passedSx }), [passedSx]);

  return (
    <Toolbar sx={sx} {...props}>
      <IconButton onClick={handleZoomChange(-increment)}>
        <RemoveIcon />
      </IconButton>

      <Typography sx={typographySx} variant="subtitle2">
        {`${Math.round(zoom * 100)}%`}
      </Typography>

      <IconButton onClick={handleZoomChange(increment)}>
        <AddIcon />
      </IconButton>
    </Toolbar>
  );
};

export default memo(Zoom);
