import * as Bananas from "bananas-commerce-admin";

import { Case } from "@/extensions/klarahill/types";

export interface MetadataCardProps extends Bananas.CardProps {
  case: Case;
}

export const MetadataCard: React.FC<MetadataCardProps> = ({
  case: c,
  ...props
}) => (
  <Bananas.Card isCompact {...props}>
    <Bananas.CardHeader title="Ärende" />
    <Bananas.CardContent>
      <Bananas.CardRow>
        <Bananas.CardFieldText
          fallback="Saknar byrå"
          formName="office"
          label="Byrå"
          value={c?.office?.name}
        />
      </Bananas.CardRow>

      <Bananas.CardRow>
        <Bananas.CardFieldText
          fallback="Saknar rådgivare"
          formName="advisor"
          label="Rådgivare"
          value={c?.advisor}
        />
      </Bananas.CardRow>
    </Bananas.CardContent>
  </Bananas.Card>
);
