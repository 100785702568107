import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import TextField from "@/components/TextField";

import {
  DEBOUNCE,
  Editable,
  Item,
} from "@/extensions/obituary/pages/detail/Story/shared";

import { useStory } from "../context";

import { Container } from "./Container";
import EditableItem from "./EditableItem";

import { Option } from "@/utils/types";

export type Variant =
  | "heading"
  | "husband-wife"
  | "default"
  | "child"
  | "grandchild"
  | "greatgrandchild"
  | "author"
  | "url";

export const variants: { value: Variant; label: string }[] = [
  { value: "heading", label: "Namn" },
  { value: "default", label: "Sörjande" },
  { value: "husband-wife", label: "Livspartner" },
  { value: "child", label: "Barn" },
  { value: "grandchild", label: "Barnbarn" },
  { value: "greatgrandchild", label: "Barnbarnsbarn" },
  { value: "author", label: "Författare" },
  { value: "url", label: "Webbsida" },
];

/** Variants used in `relatives` container. */
export const relativeVariants: Variant[] = [
  "default",
  "husband-wife",
  "child",
  "grandchild",
  "greatgrandchild",
];

/** Options for selecting variant in `relatives` container. */
export const relativeVariantOptions: Option[] = variants.filter((option) =>
  relativeVariants.includes(option.value),
);

export interface Singleline extends Item {
  type: "singleline";
  variant?: Variant;
  text: string;
}

export function isSingleline(item: Item): item is Singleline {
  return item.type === "singleline";
}

export function isVariant(value: unknown): value is Variant {
  return variants.some((option) => option.value === value);
}

export function makeSingleline(singleline: Partial<Singleline>): Singleline {
  return {
    id: crypto.randomUUID(),
    type: "singleline",
    text: "",
    ...singleline,
  };
}

const Edit: Editable<Singleline> = ({
  item: { hidden, text, variant },
  path,
  noHeader,
}) => {
  const { change, getContainerVariants, layout } = useStory();

  const title = useMemo(() => {
    let title =
      variants.find((option) => option.value === variant)?.label ?? "Fritext";
    if (hidden) title = `${title} (dold)`;
    return title;
  }, [hidden, variant]);

  const [textFieldKey, setTextFieldKey] = useState(0);
  const isAuthor = variant === "author";

  useEffect(() => {
    if (!isAuthor || text == null) return;
    setTextFieldKey((key) => key + 1);
  }, [isAuthor, text]);

  const isRelative = useMemo(() => {
    const [root] = getContainerVariants(path);
    return root === "relatives";
  }, [getContainerVariants, path]);

  const handleSplit = useMemo(
    () =>
      layout === "OBITUARY_TWO_COLUMN" && isRelative
        ? () =>
            change<Container>(path, {
              type: "container",
              flex: "row",
              variant: "relatives-row",
              id: crypto.randomUUID(),
              children: [
                makeSingleline({ text, variant }),
                makeSingleline({ variant }),
              ],
            })
        : null,
    [change, text, variant, isRelative, layout],
  );

  // Callbacks

  const handleChangeVariant = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const variant = event.target.value;
      if (isVariant(variant)) change<Singleline>(path, { variant });
    },
    [change],
  );

  const variantProps = useMemo(() => {
    return !isRelative
      ? {}
      : {
          variant,
          variants: relativeVariantOptions,
          onVariantChange: handleChangeVariant,
        };
  }, [isRelative, variant, handleChangeVariant]);

  const handleChangeText = useCallback(
    (text: string) => change<Singleline>(path, { text }),
    [change],
  );

  const Field = useMemo(
    () => (
      <TextField
        key={textFieldKey}
        debounce={isAuthor ? 2000 : DEBOUNCE} // Use a longer debounce for authors to account for key-change effect.
        disabled={Boolean(hidden)}
        name="text"
        rows={2}
        value={text}
        variant="outlined"
        onChange={handleChangeText}
      />
    ),
    [hidden, text, handleChangeText],
  );

  return noHeader ? (
    Field
  ) : (
    <EditableItem
      actions={isRelative ? "mutate" : "hide"}
      hidden={hidden}
      path={path}
      title={title}
      onSplit={handleSplit}
      {...variantProps}
    >
      {!hidden && Field}
    </EditableItem>
  );
};

export default memo(Edit);
