import * as Bananas from "bananas-commerce-admin";

import { Case, Reception } from "@/extensions/klarahill/types";

export interface MemorialCardProps extends Bananas.CardProps {
  case: Case;
  reception: Reception | undefined;
  setReception: React.Dispatch<React.SetStateAction<Reception | undefined>>;
}

export interface MemorialCardFormValues {
  visibility: "on" | "off";
  information: string;
  location_name: string;
  max_attendees: string;
  rsvp_date: string;
}

export const MemorialCard: React.FC<MemorialCardProps> = ({
  case: c,
  reception,
  setReception,
  ...props
}) => {
  const api = Bananas.useApi();

  return reception == null ? null : (
    <Bananas.Card<MemorialCardFormValues>
      isEditable
      onSubmit={async (values) => {
        const action = api.operations["case.case:update-reception"];
        if (!action)
          throw new Error('Invalid action "case.case:update-reception".');

        const visibility = values["visibility"] === "on";
        const information = values["information"];

        const rsvp_date = new Date(values["rsvp_date"]).toLocaleDateString(
          "sv-SE",
          { year: "numeric", month: "2-digit", day: "2-digit" },
        );

        const max_attendees =
          values["max_attendees"] === ""
            ? undefined
            : Number.parseInt(values["max_attendees"]);

        const response = await action.call({
          params: { case_id: c.id },
          body: { visibility, information, rsvp_date, max_attendees },
        });

        if (response.ok) {
          const updatedReception: Reception = await response.json();
          setReception(updatedReception);
        } else {
          console.error(response);
          throw new Error("uppdatering av minnesstund");
        }

        return "Inställningar uppdaterade.";
      }}
      {...props}
    >
      <Bananas.CardHeader title="Minnesstund" />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldSwitch
            formName="visibility"
            helperText="Visa information om minnesstunden i minnesrummet."
            label="Syns i minnesrummet"
            value={reception?.visibility ?? false}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldText
            formName="information"
            helperText={`Fritextfält för information gällande minnesstunden som visas på minnessidan. Exempelvis: "Efter akten inbjudes till minnesstund, anmäl senast 12 feb."`}
            label="Information"
            minRows={2}
            multiline={true}
            value={reception?.information}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldText
            disabled={true}
            formName="location_name"
            label="Plats"
            value={reception?.location_name}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldNumber
            formName="max_attendees"
            helperText="Max antal gäster som kan anmäla sig till minnesstunden. Lämna tomt för obegränsat antal gäster."
            label="Max antal gäster"
            min={1}
            value={reception?.max_attendees}
          />
          <Bananas.CardFieldDate
            formName="rsvp_date"
            label="Sista anmälningsdatum"
            value={reception?.rsvp_date}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions>
        <Bananas.CardCancelButton />
        <Bananas.CardSaveButton />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};
