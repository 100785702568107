import { memo } from "react";

import { Dialog, DialogProps } from "@mui/material";

import CreateAd, { CreateAdProps } from "./CreateAd";

export interface CreateAdDialogProps
  extends CreateAdProps,
    Omit<DialogProps, "onClose" | "onSubmit"> {
  onClose: () => void;
}

const CreateAdDialog: React.FC<CreateAdDialogProps> = ({
  ads,
  c,
  onClose,
  onSubmit,
  open,
  ...props
}) => (
  <Dialog fullWidth open={open} onClose={onClose} {...props}>
    <CreateAd ads={ads} c={c} onClose={onClose} onSubmit={onSubmit} />
  </Dialog>
);

export default memo(CreateAdDialog);
