import { useCallback, useMemo } from "react";
import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";

import {
  DayjsDeadline,
  DeadlineResponse,
  Layout,
  Publication,
} from "@/extensions/obituary/types";

import { Nullish } from "@/utils/types";

const useDeadlinesQuery = (
  publication: Nullish<Publication["abbreviation"]>,
  layout: Nullish<Layout>,
) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const deadlines = useQuery({
    queryKey: ["obituary", "deadlines", publication ?? "", layout ?? ""],
    queryFn: async () => {
      const hasPub = publication != null && (publication as string) !== "";
      const hasLayout = layout != null && (layout as string) !== "";
      if (!hasPub || !hasLayout) return null;

      const action = api.operations["obituary.deadline:list"];
      if (!action) throw new Error('Invalid action "obituary.deadline:list".');

      const response = await action.call({ params: { publication, layout } });
      if (response.ok) {
        const data: DeadlineResponse[] = await response.json();

        const parsedDeadlines: DayjsDeadline[] = [];
        for (const deadline of data) {
          parsedDeadlines.push({
            deadlineTime: dayjs(deadline.deadline_time),
            publicationDate: dayjs(deadline.publication_date),
          });
        }

        return parsedDeadlines;
      } else {
        enqueueSnackbar("Kunde inte hämta deadlines.", { variant: "error" });
        return null;
      }
    },
  });

  /** Set of publication dates, used for otherwise expensive calendar date disable lookups. */
  const publicationDates = useMemo<Set<number>>(() => {
    if (deadlines?.data == null) return new Set();
    return new Set(
      deadlines.data.map((d) => d.publicationDate.startOf("day").valueOf()),
    );
  }, [deadlines]);

  const hasPublicationDates = publicationDates?.size > 0;

  /** Filters publication date calendar. */
  const handleShouldDisableDate = useCallback(
    (date: Dayjs) => {
      if (!hasPublicationDates) return true;
      const isAvaliable = publicationDates.has(date.startOf("day").valueOf());
      const inThePast = date.isBefore(dayjs(), "day");
      return !isAvaliable || inThePast;
    },
    [deadlines, publicationDates],
  );

  /** Default suggested date. Probably not what you want, but better than nothing. */
  const firstAvaliableDate = useMemo(() => {
    if (!hasPublicationDates) return null;
    const date = Array.from(publicationDates)
      .sort((a, b) => a - b)
      .find((d) => dayjs(d).isAfter(dayjs(), "day"));
    return date ? dayjs(date) : null;
  }, [publicationDates]);

  return { ...deadlines, handleShouldDisableDate, firstAvaliableDate };
};

export default useDeadlinesQuery;
