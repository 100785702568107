import * as Bananas from "bananas-commerce-admin";

import { Case, Memorial } from "@/extensions/klarahill/types";

export interface MemorialStatusCardProps extends Bananas.CardProps {
  case: Case;
  memorial: Memorial;
}

export const MemorialStatusCard: React.FC<MemorialStatusCardProps> = ({
  case: { florist },
  memorial,
  ...props
}) => (
  <Bananas.Card isCompact {...props}>
    <Bananas.CardHeader title="Minnesrum" />
    <Bananas.CardContent>
      <Bananas.CardRow>
        <Bananas.CardFieldText
          formName="marked_as_paid_at"
          label="Betalt"
          value={memorial.marked_as_paid_at ? "Ja" : "Nej"}
        />
      </Bananas.CardRow>

      <Bananas.CardRow>
        <Bananas.CardFieldText
          formName="published_at"
          label="Publicerat"
          value={memorial.published_at ? "Ja" : "Nej"}
        />
      </Bananas.CardRow>

      <Bananas.CardRow>
        <Bananas.CardFieldText
          formName="purchases_possible"
          label="Blomsteraffär öppen"
          value={memorial.purchases_possible ? "Ja" : "Nej"}
        />
      </Bananas.CardRow>

      <Bananas.CardRow>
        <Bananas.CardFieldText
          formName="florist"
          label="Blomsterleverantör"
          value={florist?.name}
        />
      </Bananas.CardRow>
    </Bananas.CardContent>
  </Bananas.Card>
);
