import { memo, useMemo } from "react";

import {
  Alert,
  Grid2 as Grid,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

import {
  ObituaryImage,
  ObituarySymbol,
  ObituarySymbolFilter,
  ObituarySymbolOrImage,
} from "@/extensions/obituary/types";

import ImageCard from "./ImageCard";

import * as object from "@/utils/object";
import { Nullish } from "@/utils/types";

const styles: Record<string, SxProps> = {
  root: { minHeight: "90vh", bgcolor: "grey.200" },
  grid: { p: [3, 2] },
  item: { height: "100%", width: "100%" },
  heading: { ml: 1 },
  alert: { width: 420 },
};

export interface ImageLibraryProps {
  images: ObituaryImage[];
  filter: ObituarySymbolFilter;
  symbols: ObituarySymbol[];
  selected: Nullish<ObituarySymbolOrImage>;
  onSelectImage: (symbol: ObituarySymbolOrImage) => void;
}

const ImageLibrary: React.FC<ImageLibraryProps> = ({
  filter,
  images,
  onSelectImage,
  selected,
  symbols,
}) => {
  const hasSymbols = useMemo(() => symbols.length > 0, [symbols]);

  const hasFilter = useMemo(
    () =>
      Object.entries(object.omitNullish(filter as Record<string, unknown>))
        .length > 0,
    [filter],
  );

  return (
    <Stack sx={styles.root}>
      <Stack gap={4} sx={styles.grid}>
        {Array.isArray(images) && images.length > 0 && !hasFilter && (
          <Stack gap={2}>
            <Typography sx={styles.heading} variant="h6">
              Uppladdade symboler
            </Typography>

            <Grid container spacing={1.5}>
              {images?.map((symbol) => (
                <Grid key={symbol.id} size={{ xs: 3, md: 2 }} sx={styles.item}>
                  <ImageCard
                    selected={selected?.id === symbol.id}
                    symbol={symbol}
                    onClick={onSelectImage}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}

        {hasSymbols ? (
          <Stack gap={2}>
            {!hasFilter && (
              <Typography sx={styles.heading} variant="h6">
                Bibliotek
              </Typography>
            )}

            <Grid container spacing={1.5}>
              {symbols.map((symbol) => (
                <Grid key={symbol.id} size={{ xs: 3, md: 2 }} sx={styles.item}>
                  <ImageCard
                    selected={selected?.id === symbol.id}
                    symbol={symbol}
                    onClick={onSelectImage}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ) : (
          <Stack direction="row" gap={2} justifyContent="flex-start">
            <Alert severity="info" sx={styles.alert} variant="outlined">
              Inga symboler matchade din sökning.
            </Alert>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(ImageLibrary);
