import { memo } from "react";

import {
  Alert,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
} from "@mui/material";

import dayjs from "dayjs";

import SubtitledLabel from "@/components/SubtitledLabel";

import AdMeta from "@/extensions/obituary/components/AdMeta";
import { Ad, layoutName, publicationName } from "@/extensions/obituary/types";

import { Nullish } from "@/utils/types";

export interface AdListProps {
  ads: Ad[];
  allSelected?: boolean;
  gap?: number;
  label?: string;
  selectablePred?: (ad: Ad) => boolean;
  showAdMeta?: boolean;
  showWarnings?: boolean;
}

const AdList: React.FC<AdListProps> = ({
  ads,
  allSelected,
  gap,
  label,
  selectablePred,
  showAdMeta,
  showWarnings,
}) => (
  <FormControl component="fieldset" name="ads" variant="standard">
    {label && (
      <FormLabel component="legend" sx={{ mb: 0.5 }}>
        {label}
      </FormLabel>
    )}

    <FormGroup>
      <Stack gap={gap ?? 2}>
        {ads.map((ad) => {
          const isSelectable = selectablePred ? selectablePred(ad) : true;
          let error: Nullish<string> = null;
          if (ad.publication_date == null) {
            error = "Införandedatum saknas.";
          } else if (dayjs(ad.publication_date).isBefore(dayjs())) {
            error = "Införandedatum har passerat.";
          }

          let warning: Nullish<string> = null;
          if (ad.approved_at == null) {
            warning = "Annonsen har inte godkänts av kund.";
          }

          return (
            <Stack key={ad.id} gap={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={isSelectable && allSelected}
                    disabled={!isSelectable}
                    id={`ad-${ad.id.toString()}`}
                    name={`ad-${ad.id.toString()}`}
                    sx={{ cursor: isSelectable ? "pointer" : "not-allowed" }}
                  />
                }
                label={
                  <SubtitledLabel
                    subtitle={layoutName[ad.layout_variant]}
                    title={publicationName[ad.publication_abbreviation]}
                  />
                }
                name="ad"
              />

              {showAdMeta && (
                <>
                  <Divider sx={{ ml: 4 }} />
                  <AdMeta showPublishInfo ad={ad} sx={{ pl: 4 }} />
                </>
              )}

              {showWarnings && (
                <Stack gap={1} mt={2}>
                  {error != null && (
                    <Alert severity="error" sx={{ ml: 4 }}>
                      {error}
                    </Alert>
                  )}

                  {warning != null && (
                    <Alert severity="warning" sx={{ ml: 4 }}>
                      {warning}
                    </Alert>
                  )}
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    </FormGroup>
  </FormControl>
);

export default memo(AdList);
