import { memo, useCallback } from "react";

import { SelectChangeEvent } from "@mui/material/Select";

import Select from "@/components/Select";

import {
  Editable,
  Item,
} from "@/extensions/obituary/pages/detail/Story/shared";

import { useStory } from "../context";

import EditableItem from "./EditableItem";

import { Option } from "@/utils/types";

export type Size = "small" | "medium" | "large";

export interface Divider extends Item {
  type: "divider";
  size?: Size;
}

export function isDivider(item: Item): item is Divider {
  return item.type === "divider";
}

export const sizes: Option[] = [
  { value: "small", label: "Liten" },
  { value: "medium", label: "Mellan" },
  { value: "large", label: "Stor" },
];

export function isSize(value: unknown): value is Size {
  return sizes.some((variant) => variant.value === value);
}

const Edit: Editable<Divider> = ({ item: { size, hidden }, path }) => {
  const { change } = useStory();

  const handleChangeVariant = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const size = event.target.value;
      if (isSize(size)) change<Divider>(path, { size });
    },
    [change],
  );

  return (
    <EditableItem
      hidden={hidden}
      path={path}
      title={hidden ? "Linje (dold)" : "Linje"}
    >
      {!hidden && (
        <Select
          options={sizes}
          value={size ?? "small"}
          onChange={handleChangeVariant}
        />
      )}
    </EditableItem>
  );
};

export default memo(Edit);
