import * as Bananas from "bananas-commerce-admin";

import { Case, Memorial } from "@/extensions/klarahill/types";

export interface SettingsCardProps extends Bananas.CardProps {
  memorial: Memorial;
  setMemorial: React.Dispatch<React.SetStateAction<Memorial>>;
}

export interface SettingsCardFormValues {
  date: string;
  marked_as_paid_at: string;
  published_at: string;
  purchases_enabled: string;
  show_funeral_date: string;
}

export type Payload = Partial<
  Pick<
    Case["memorial"],
    | "marked_as_paid_at"
    | "published_at"
    | "purchases_enabled"
    | "show_funeral_date"
  >
>;

export const SettingsCard: React.FC<SettingsCardProps> = ({
  memorial,
  setMemorial,
  ...props
}) => {
  const api = Bananas.useApi();

  return (
    <Bananas.Card<SettingsCardFormValues>
      isCompact
      isEditable
      onSubmit={async (values) => {
        const action = api.operations["case.case:update-memorial"];
        if (!action)
          throw new Error('Invalid action "case.case:update-memorial".');

        const markedAsPaidAt = values["marked_as_paid_at"] === "on";
        const publishedAt = values["published_at"];

        const body: Payload = {
          purchases_enabled: values["purchases_enabled"] === "on",
          show_funeral_date: values["show_funeral_date"] === "on",
        };

        if (markedAsPaidAt && !memorial.marked_as_paid_at) {
          // Only update the date if we did not have a date before.
          body.marked_as_paid_at = new Date().toISOString();
        }

        if (!markedAsPaidAt && memorial.marked_as_paid_at) {
          // Only update the date if we had a date before.
          body.marked_as_paid_at = null;
        }

        if (publishedAt != null) {
          const date = new Date(publishedAt);
          body.published_at = date.toISOString();
        }

        if (Object.keys(body).length === 0) return "Inga ändringar.";

        const response = await action.call({
          params: { memorial_id: memorial.id },
          body,
        });

        if (response.ok) {
          const updatedMemorial: Case["memorial"] = await response.json();
          setMemorial(updatedMemorial);
        } else {
          console.error(response);
          throw new Error("uppdatering av inställningar");
        }

        return "Inställningar uppdaterade.";
      }}
      {...props}
    >
      <Bananas.CardHeader title="Inställningar" />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            disabled
            formName="published_at"
            label="Publicerat"
            value={memorial.published_at ? "Ja" : "Nej"}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldDate
            formName="published_at"
            label="Publikationsdatum"
            value={memorial.published_at}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldSwitch
            formName="marked_as_paid_at"
            helperText="Obetalda minnesrum avpubliceras automatiskt 30 dagar efter att begravning har ägt rumt. Betalda minnesrum avpubliceras aldrig."
            label="Betalt"
            value={Boolean(memorial.marked_as_paid_at)}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldSwitch
            formName="purchases_enabled"
            helperText="Blomsterbutiken döljs automatiskt om datumet för begravningen har passerat eller om ärendet saknar en florist."
            label="Visa blomsterbutik"
            value={Boolean(memorial.purchases_enabled)}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldSwitch
            formName="show_funeral_date"
            helperText="Visa tid och datum för begravningen i minnesrummet."
            label="Visa begravningsdatum"
            value={Boolean(memorial.show_funeral_date)}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions>
        <Bananas.CardCancelButton />
        <Bananas.CardSaveButton />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};
