import { memo } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Alert, Typography } from "@mui/material";

export interface ErrorCardProps extends Bananas.CardProps {
  errorMessage: string;
}

const ErrorCard: React.FC<ErrorCardProps> = ({
  errorMessage,
  sx,
  ...props
}) => (
  <Bananas.Card
    {...props}
    sx={{
      p: 0,
      width: "fit-content",
      maxWidth: 600,
      mr: "auto",
      ml: "auto",
      mt: 4,
      height: "auto",
      ...sx,
    }}
  >
    <Alert severity="info" sx={{ borderRadius: 2 }} variant="outlined">
      <Typography variant="body1">{errorMessage}</Typography>
    </Alert>
  </Bananas.Card>
);

export default memo(ErrorCard);
