import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import * as Bananas from "bananas-commerce-admin";

import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import DiversityIcon from "@mui/icons-material/Diversity1Outlined";
import FireplaceIcon from "@mui/icons-material/FireplaceOutlined";
import FlowerIcon from "@mui/icons-material/LocalFloristOutlined";
import { TabPanel } from "@mui/lab";

import { Case } from "@/extensions/klarahill/types";

import { CaseDetails } from "./Case";
import { Gifts } from "./Gifts";
import { Memorial } from "./Memorial";
import { Messages } from "./Messages";
import { Orders } from "./Orders";
import { Reception } from "./Reception";

import * as featureFlag from "@/utils/featureFlag";
import { Nullish } from "@/utils/types";

export type CaseTab =
  | "case"
  | "memorial"
  | "reception"
  | "messages"
  | "orders"
  | "gifts"
  | "obituary";

const CasesDetailsPage: Bananas.types.PageComponent<Case> = ({ data }) => {
  const api = Bananas.useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab: Nullish<CaseTab> = searchParams.get("tab") as CaseTab;

  const { first_name, last_name } = data.deceased;
  const fullName = `${first_name} ${last_name}`;

  const hasMemorial = data.memorial != null;
  const showObituary = featureFlag.active.has("OBITUARY");

  const contrib = useMemo(() => api.findContrib("case"), [api]);

  return (
    <Bananas.Page defaultTab="case" tabSearchParam="tab">
      <Bananas.Header variant="opaque">
        <Bananas.TitleBar title={fullName} />

        <Bananas.Tabs
          selectionFollowsFocus
          aria-label="Navigation för begravningsärende."
          orientation="horizontal"
          scrollButtons="auto"
          variant="scrollable"
          onChange={(tab) => {
            setSearchParams((sp) => ({
              ...sp,
              tab,
            }));
          }}
        >
          <Bananas.Tab icon={<ArticleIcon />} label="Ärende" value="case" />
          <Bananas.Tab
            disabled={!hasMemorial}
            icon={<FireplaceIcon />}
            label="Minnesrum"
            value="memorial"
          />

          <Bananas.Tab
            disabled={!hasMemorial}
            icon={<DiversityIcon />}
            label="Minnen"
            value="messages"
          />

          <Bananas.Tab
            disabled={!hasMemorial}
            icon={<CalendarMonthOutlinedIcon />}
            label="Minnesstund"
            value="reception"
          />

          <Bananas.Tab
            disabled={!hasMemorial}
            icon={<FlowerIcon />}
            label="Blomsterordrar"
            value="orders"
          />

          <Bananas.Tab
            disabled={!hasMemorial}
            icon={<CardGiftcardOutlinedIcon />}
            label="Minnesgåvor"
            value="gifts"
          />

          {showObituary &&
            contrib.map(({ id, component }) => (
              <Bananas.Tab
                key={id}
                // @ts-expect-error - TODO
                icon={component?.icon}
                label={component?.title ?? "MISSING TITLE"}
                value={id}
              />
            ))}
        </Bananas.Tabs>
      </Bananas.Header>

      {activeTab === "case" || activeTab == null ? (
        <CaseDetails case={data} />
      ) : activeTab === "memorial" ? (
        <Memorial case={data} />
      ) : activeTab === "reception" ? (
        <Reception case={data} />
      ) : activeTab === "messages" ? (
        <Messages case={data} />
      ) : activeTab === "orders" ? (
        <Orders case={data} />
      ) : activeTab === "gifts" ? (
        <Gifts case={data} />
      ) : (
        contrib.map((operation) => (
          <TabPanel key={operation.id} value={activeTab}>
            <Bananas.ComponentLoader
              operation={operation}
              query={{ case_id: data.id }}
            />
          </TabPanel>
        ))
      )}
    </Bananas.Page>
  );
};

export default CasesDetailsPage;
