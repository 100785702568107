import * as Bananas from "bananas-commerce-admin";

import { Case, Funeral } from "@/extensions/klarahill/types";

export interface FuneralCardProps extends Bananas.CardProps {
  case: Case;
  funeral: Funeral | undefined;
  setFuneral: React.Dispatch<React.SetStateAction<Funeral | undefined>>;
}

export interface FuneralCardFormValues {
  information: string;
  location_name: string;
  funeral_at: Date;
  address: string;
  city: string;
}

export const FuneralCard: React.FC<FuneralCardProps> = ({
  case: c,
  funeral,
  setFuneral,
  ...props
}) => {
  const api = Bananas.useApi();

  return (
    <Bananas.Card<FuneralCardFormValues>
      isEditable
      onSubmit={async (values) => {
        const action = api.operations["case.case:update-funeral"];
        if (!action)
          throw new Error('Invalid action "case.case:update-funeral".');

        const information = values?.["information"] as string;
        if (information == null) {
          return "Kunde inte hitta någon begravningsinformation.";
        }

        const response = await action.call({
          params: { case_id: c.id },
          body: { information },
        });

        if (response.ok) {
          const updatedFuneral: Case["funeral"] = await response.json();
          setFuneral(updatedFuneral);
        } else {
          console.error(response);
          throw new Error("uppdatering av begravning");
        }

        return "Inställningar uppdaterade.";
      }}
      {...props}
    >
      <Bananas.CardHeader title="Begravning" />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            formName="information"
            helperText={`Fritextfält för information gällande begravningen som visas på minnessidan. Exempelvis: "Begravningsceremonin äger rum i Kungsholms kyrka, valfri klädsel."`}
            label="Information"
            minRows={2}
            multiline={true}
            // TODO Show — if no information
            value={funeral?.information}
          />
        </Bananas.CardRow>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            disabled={true}
            formName="location_name"
            helperText="Observera att plats inte visas på minnessidan. Skriv detta i informationsfältet om det ska visas."
            label="Plats"
            value={funeral?.location_name}
          />
          <Bananas.CardFieldDate
            disabled={true}
            formName="funeral_at"
            label="Datum"
            value={
              funeral?.funeral_at != null
                ? new Date(funeral?.funeral_at)
                : undefined
            }
          />
        </Bananas.CardRow>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            disabled={true}
            formName="address"
            label="Adress"
            value={funeral?.address}
          />
          <Bananas.CardFieldText
            disabled={true}
            formName="city"
            label="Stad"
            value={funeral?.city && `${funeral?.zip_code} ${funeral?.city}`}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions>
        <Bananas.CardCancelButton />
        <Bananas.CardSaveButton />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};
