import { useCallback, useEffect, useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import { useSnackbar } from "notistack";

import {
  Ad,
  ObituaryPreviewPayload,
  PreviewFormat,
} from "@/extensions/obituary/types";

import useMap from "@/hooks/useMap";

import * as stream from "@/utils/stream";

type Hash = string;

const usePreviewQuery = (
  id: Ad["id"],
  storyHash: Hash,
  payload: Partial<ObituaryPreviewPayload>,
  format: PreviewFormat = "svg",
) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [preview, setPreview] = useState<string>();
  const [cachedPreviews, { set: setCachedPreviews }] = useMap<Hash, string>();

  // Save the latest (NonNullish) preview to avoid flickering.
  const [lastPreview, setLastPreview] = useState<string>();
  useEffect(() => {
    if (preview != null && preview !== "") {
      setLastPreview(preview);
    }
  }, [preview]);

  const handleGetPreview = useCallback(
    async (payload: Partial<ObituaryPreviewPayload>, storyHash: Hash) => {
      if (!Object.values(payload).every(Boolean)) {
        console.error("Invalid payload:", payload);
        return null;
      }

      const action = api.operations["obituary.preview:create"];
      if (!action) throw new Error('Invalid action "obituary.preview:create".');

      const response = await action.call({
        params: { format },
        body: payload as ObituaryPreviewPayload,
      });

      if (response.ok) {
        const reader = response.body?.getReader();
        if (reader == null) throw new Error("Invalid response body.");

        const preview = await stream.toDataURL(reader, "svg");
        setCachedPreviews(storyHash, preview);
        setPreview(preview);
      } else {
        enqueueSnackbar("Kunde inte hämta förhandsvisning.", {
          variant: "error",
        });
        console.error(response.statusText);
        return null;
      }
    },
    [id, format],
  );

  useEffect(() => {
    const getPreview = async () => await handleGetPreview(payload, storyHash);

    if (id == null || storyHash == null) return;
    if (cachedPreviews.has(storyHash)) {
      setPreview(cachedPreviews.get(storyHash));
    } else {
      getPreview();
    }
  }, [id, payload, storyHash]);

  return { preview, lastPreview };
};

export default usePreviewQuery;
