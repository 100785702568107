import { useId } from "react";
import * as Bananas from "bananas-commerce-admin";
import { useCardContext } from "bananas-commerce-admin";

import { Box, Button, TextField, Typography } from "@mui/material";

import FormDatePicker from "@/components/FormDatePicker";

import type { Obituary } from "@/extensions/klarahill/types";

import { ObituaryChangeData } from "./shared";

export interface ObituaryCardProps {
  type: "edit" | "create";
  obituary: Obituary;
  onChange?: (data: ObituaryChangeData) => void;
  onDelete?: () => void;
}

const Obituary: React.FC<ObituaryCardProps> = ({
  type,
  obituary,
  onChange,
  onDelete,
}) => {
  const { isEditing } = useCardContext();
  const id = useId();

  if (!isEditing) {
    if (type === "create") return null;

    return (
      <>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            formName={`${id}+label`}
            label="Publicerad i"
            value={obituary.source}
          />

          <Bananas.CardFieldText
            formName={`${id}+date`}
            label="Publiceringsdatum"
            value={obituary.date}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldImage
            formName={`${id}+image`}
            label="Dödsannons"
            value={obituary.image}
          />
        </Bananas.CardRow>
      </>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 1 }}>
          <TextField
            fullWidth
            defaultValue={obituary.source}
            label="Publicerad i"
            name="source"
            required={true}
            variant="outlined"
            onChange={(e) => onChange?.({ source: e.target.value })}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <FormDatePicker
            label="Publiceringsdatum"
            name="date"
            value={obituary.date}
            onChange={(value) => {
              if (value == null) return;
              const date = new Date(value);
              if (!Number.isNaN(date.getTime())) onChange?.({ date });
            }}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 3 }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            gap: 0.5,
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography sx={{ color: "grey.600" }} variant="subtitle2">
            Dödsannons
          </Typography>

          <input
            accept="image/*"
            name="image"
            required={type === "create"}
            type="file"
            onChange={(e) => onChange?.({ image: e.target.files![0] })}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button size="medium" variant="outlined" onClick={onDelete}>
            Ta bort
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Obituary;
