import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import CollectionsIcon from "@mui/icons-material/Collections";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HeightIcon from "@mui/icons-material/Height";
import { Box, Button, Stack, SxProps } from "@mui/material";

import { useSnackbar } from "notistack";

import Slider from "@/components/Slider";

import ImageLibraryDialog from "@/extensions/obituary/components/ImageLibraryDialog";
import {
  DEBOUNCE,
  Editable,
  Item,
  MAX_IMAGE_HEIGHT,
} from "@/extensions/obituary/pages/detail/Story/shared";
import useImagesQuery from "@/extensions/obituary/queries/useImagesQuery";
import { ObituaryImage, ObituarySymbol } from "@/extensions/obituary/types";

import { useStory } from "../context";

import EditableItem from "./EditableItem";

import { Nullish, Option } from "@/utils/types";

const styles: Record<string, SxProps> = {
  root: {
    bgcolor: "background.paper",
    borderRadius: 1,
    border: "1px solid",
    borderColor: "grey.400",
    p: [0.5, 1],
  },

  button: {
    justifyContent: "flex-start",
    gap: 0.5,
  },
};

export interface Image extends Item {
  type: "image";
  variant: "top-image";
  src: Nullish<string>;
  height: number;
}

export function isImage(item: Item): item is Image {
  return item.type === "image";
}

export const variants: Option[] = [{ value: "top-image", label: "Symbol" }];

export function isVariant(value: unknown): value is Image["variant"] {
  return variants.some((option) => option.value === value);
}

const Edit: Editable<Image> = ({ item: { hidden, height, variant }, path }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { c, change } = useStory();

  const case_id = c.id;
  const images = useImagesQuery(case_id);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const uploadFormRef = useRef<HTMLFormElement>();

  const title = useMemo(() => {
    let title = variants.find((option) => option.value === variant)?.label;
    if (title != null && hidden) title = `${title} (dold)`;
    return title ?? "Image";
  }, [hidden, variant]);

  /// Callbacks

  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (isUploading) return;
      setIsUploading(true);

      const file = event.target.files?.[0];
      if (file == null) {
        setIsUploading(false);
        enqueueSnackbar("Ingen fil vald.", { variant: "error" });
        return;
      }

      const form = uploadFormRef.current;
      if (form == null) {
        setIsUploading(false);
        enqueueSnackbar("Kunde inte hitta formulär.", { variant: "error" });
        return;
      }

      const formData = new FormData(form);

      const action = api.operations["obituary.image:create"];
      if (action == null) {
        setIsUploading(false);
        throw new Error('Invalid action "obituary.image:create".');
      }

      const response = await action.call({
        params: { case_id },
        body: formData,
      });

      if (response.ok) {
        const { name }: ObituaryImage = await response.json();
        change<Image>(path, { src: name });
        images.refetch();
      } else {
        enqueueSnackbar("Ett fel inträffade vid uppladdning av bild.", {
          variant: "error",
        });
      }

      setIsUploading(false);
    },
    [case_id, change],
  );

  const handleChangeHeight = useCallback(
    (height: number) => change<Image>(path, { height }),
    [change],
  );

  const handleOpenDialog = useCallback(() => setDialogOpen(true), []);
  const handleCloseDialog = useCallback(() => setDialogOpen(false), []);

  const handleSelectImage = useCallback(
    (symbol: ObituaryImage | ObituarySymbol) => {
      let src: Nullish<string>;
      if ("file_name" in symbol) {
        src = (symbol as ObituarySymbol).file_name;
      } else {
        src = (symbol as ObituaryImage).name;
      }

      if (src == null) {
        enqueueSnackbar("Kunde inte välja bild.", { variant: "error" });
        return;
      }

      change<Image>(path, { src });
    },
    [],
  );

  return (
    <EditableItem hidden={hidden} path={path} title={title}>
      {!hidden && (
        <Stack gap={1}>
          <Stack gap={2} sx={styles.root}>
            <Slider
              debounce={DEBOUNCE}
              icon={<HeightIcon />}
              label="Symbolhöjd (mm)"
              marks={[
                { value: 10, label: "10" },
                { value: 15, label: "15" },
                { value: 20, label: "20" },
                { value: 25, label: "25" },
                { value: 30, label: "30" },
              ]}
              max={MAX_IMAGE_HEIGHT}
              min={10}
              step={1}
              value={height}
              onChange={handleChangeHeight}
            />
          </Stack>

          <Box
            ref={uploadFormRef}
            component="form"
            id="postImage"
            sx={{ width: "100%" }}
          >
            <Button
              disableRipple
              fullWidth
              disabled={isUploading}
              startIcon={<CollectionsIcon />}
              sx={styles.button}
              tabIndex={-1}
              onClick={handleOpenDialog}
            >
              Välj symbol från bibliotek
            </Button>

            <Button
              disableRipple
              fullWidth
              component="label"
              disabled={isUploading}
              startIcon={<FileUploadIcon />}
              sx={styles.button}
              tabIndex={-1}
            >
              {isUploading ? "Laddar upp symbol…" : "Ladda upp symbol"}
              <Box
                aria-hidden
                accept="image/*"
                component="input"
                name="file"
                sx={{ display: "none" }}
                type="file"
                onChange={handleFileUpload}
              />
            </Button>
          </Box>
        </Stack>
      )}

      <ImageLibraryDialog
        c={c}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSelectImage}
      />
    </EditableItem>
  );
};

export default memo(Edit);
