import React, { memo, useCallback } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Stack, StackProps, Tooltip } from "@mui/material";

import Tab from "./Tab";

import { Nullish, Option } from "@/utils/types";

export const LIST_MAKER = "LIST" as const;

export interface ListTabsProps extends Omit<StackProps, "onChange"> {
  activeTab: Option["value"];
  onChange: (tab: Option) => void;
  tabs: Nullish<Option[]>;
}

const ListTabs: React.FC<ListTabsProps> = ({
  activeTab,
  onChange,
  tabs,
  ...props
}) => {
  const handleBackToList = useCallback(() => {
    onChange({ value: LIST_MAKER, label: "List" });
  }, []);

  return (
    <Stack direction="row" gap={1.5} {...props}>
      <Tooltip arrow title="Tillbaka till annonser">
        <IconButton sx={{ mt: -0.5, mr: -0.5 }} onClick={handleBackToList}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>

      {tabs?.map((tab, i) => (
        <Tab
          key={i}
          active={activeTab === tab.value}
          tab={tab}
          onClick={onChange}
        />
      ))}
    </Stack>
  );
};

export default memo(ListTabs);
