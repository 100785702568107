import * as Bananas from "bananas-commerce-admin";

import FireplaceOutlinedIcon from "@mui/icons-material/FireplaceOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import FlowerIcon from "@mui/icons-material/LocalFloristOutlined";

import { OpenAPI } from "openapi-types";

import CasesDetailPage from "./pages/case/Detail";
import CasesListPage from "./pages/case/List";
import OrderListPage from "./pages/order/List";
import ReportPage from "./pages/report/Report";

interface Route {
  page: () => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Bananas.types.PageComponent<any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Promise<Bananas.types.PageComponent<any>>;
  request?: OpenAPI.Request;
  defaultRequest?: OpenAPI.Request;
}

const routes: Record<string, Record<string, Route>> = {
  case: {
    list: {
      page: () => CasesListPage,
      defaultRequest: {
        query: {
          // NOTE: Make sure that these match the default states of page.
          panel_filter: "active",
          only_mine: "true",
        },
      },
    },
    retrieve: { page: () => CasesDetailPage },
  },
  order: {
    list: { page: () => OrderListPage },
    order: { page: () => OrderListPage },
  },
  report: {
    list: { page: () => ReportPage },
    report: { page: () => ReportPage },
  },
};

export const caseRouter: Bananas.RouterExtension = {
  app: "case",
  pages: (route) => {
    const hit = routes[route.view]?.[route.action];
    return hit?.page != null
      ? {
          page: hit.page(),
          request: hit.request,
          defaultRequest: hit.defaultRequest,
        }
      : undefined;
  },
};

export const orderRouter: Bananas.RouterExtension = {
  app: "order",
  pages: (route) => {
    const hit = routes[route.view]?.[route.action];
    return hit?.page != null
      ? {
          page: hit.page(),
          request: hit.request,
          defaultRequest: hit.defaultRequest,
        }
      : undefined;
  },
};

export const reportRouter: Bananas.RouterExtension = {
  app: "report",
  pages: () => {
    return {
      page: (async () => (await import("./pages/report/Report")).default)(),
      offline: true,
    };
  },
};

export const navigation: Bananas.types.NavigationOverrides = {
  "case.case:list": {
    title: "Ärenden",
    icon: FireplaceOutlinedIcon,
  },
  "order.order:list": {
    title: "Ordrar",
    icon: FlowerIcon,
  },
  "report.order:list": {
    title: "Rapporter",
    icon: InsightsOutlinedIcon,
    group: "analytics",
    route: {
      id: "report.order:list",
      app: "report",
      view: "report",
      action: "list",
      title: "Rapporter",
      navigation: false,
      path: "/report/",
      page: "report",
    },
  },
} as const;
