import { useCallback, useMemo, useState } from "react";

import { Box, BoxProps } from "@mui/material";

import { get } from "typedash/get";

import Container, {
  Container as IContainer,
  isContainer,
} from "./Item/Container";
import Date, { isDate } from "./Item/Date";
import Divider, { isDivider } from "./Item/Divider";
import Image, { isImage } from "./Item/Image";
import Multiline, { isMultiline } from "./Item/Multiline";
import Row, { isRow } from "./Item/Row";
import Singleline, { isSingleline } from "./Item/Singleline";
import { StoryContext, StoryContextProps } from "./context";
import { Item, Story } from "./shared";

import * as object from "@/utils/object";
import { Nullish } from "@/utils/types";

export * as container from "./Item/Container";
export * as date from "./Item/Date";
export * as divider from "./Item/Divider";
export * as image from "./Item/Image";
export * as multiline from "./Item/Multiline";
export * as row from "./Item/Row";
export * as singleline from "./Item/Singleline";
export type { Item, Story } from "./shared";
export { default as template } from "./template";

export interface EditProps extends BoxProps {
  story: Story;
  parentPath?: object.Path;
}

export const Edit: React.FC<EditProps> = ({ story, parentPath, ...props }) => {
  parentPath ??= [];

  const Fields = useMemo(() => {
    return story?.map((item, i) => {
      const path = [...parentPath, i];

      return isContainer(item) ? (
        isRow(item) ? (
          <Row key={item.id} item={item} path={path} />
        ) : (
          <Container key={(item as IContainer).id} item={item} path={path} />
        )
      ) : isDate(item) ? (
        <Date key={item.id} item={item} path={path} />
      ) : isDivider(item) ? (
        <Divider key={item.id} item={item} path={path} />
      ) : isImage(item) ? (
        <Image key={item.id} item={item} path={path} />
      ) : isMultiline(item) ? (
        <Multiline key={item.id} item={item} path={path} />
      ) : isSingleline(item) ? (
        <Singleline key={item.id} item={item} path={path} />
      ) : (
        <Box key={item?.id ?? i}>
          Unknown item {JSON.stringify(item)} at {path}
        </Box>
      );
    });
  }, [story]);

  return (
    <Box component="section" {...props}>
      {Fields}
    </Box>
  );
};

export interface EditPanelProps
  extends Omit<
    StoryContextProps,
    "change" | "getContainerVariants" | "openContainer" | "setOpenContainer"
  > {}

export const EditPanel: React.FC<EditPanelProps> = ({
  story,
  dispatch,
  ...props
}) => {
  const [openContainer, setOpenContainer] = useState<Nullish<string>>();

  const change = useCallback(
    <T extends Item>(path: object.Path, payload: Partial<T>) =>
      dispatch({ type: "change", path, payload }),
    [dispatch],
  );

  /**
   * Get the container variants of an item's parents. Used to contextually render controls.
   */
  const getContainerVariants = useCallback(
    (path: object.Path) => {
      const containerTypes: IContainer["variant"][] = [];
      const keys: object.Path = [];

      for (const key of path) {
        keys.push(key);
        const item = get(story, keys.join(".")) as Item;
        if (item != null && isContainer(item))
          containerTypes.push(item.variant);
      }

      return containerTypes;
    },
    [story],
  );

  const value = useMemo(
    () => ({
      change,
      dispatch,
      getContainerVariants,
      openContainer,
      setOpenContainer,
      story,
      ...props,
    }),
    [
      change,
      dispatch,
      getContainerVariants,
      openContainer,
      props,
      setOpenContainer,
      story,
    ],
  );

  return (
    <StoryContext.Provider value={value}>
      <Edit story={story} />
    </StoryContext.Provider>
  );
};
