import * as Bananas from "bananas-commerce-admin";

import { Case, Memorial } from "@/extensions/klarahill/types";

export interface PresentationCardProps extends Bananas.CardProps {
  case: Case;
  memorial: Memorial;
  setMemorial: React.Dispatch<React.SetStateAction<Memorial>>;
}

export interface PresentationCardFormValues {
  image: File;
  header_image: File;
  text: string;
}

export const PresentationCard: React.FC<PresentationCardProps> = ({
  memorial,
  setMemorial,
  ...props
}) => {
  const api = Bananas.useApi();

  return (
    <Bananas.Card<PresentationCardFormValues>
      isEditable
      onSubmit={async (values, event) => {
        const action = api.operations["case.case:update-memorial"];
        if (action == null) {
          throw new Error('Invalid action "case.case:update-memorial".');
        }

        const greeting = values.text;
        const portraitImage = values.image;
        const headerImage = values.header_image;

        if (greeting.length > 0 && greeting !== memorial.text) {
          const response = await action.call({
            params: { memorial_id: memorial.id },
            body: {
              text: greeting,
              // Only update the `text` field.
              marked_as_paid_at: memorial.marked_as_paid_at,
              published_at: memorial.published_at,
            },
          });

          if (response.ok) {
            const updatedMemorial: Case["memorial"] = await response.json();
            setMemorial(updatedMemorial);
          } else {
            console.error(response);
            throw new Error("uppdaterande av hälsning");
          }
        }

        if (portraitImage.size > 0 || headerImage.size > 0) {
          const action = api.operations["case.case:upload-memorial-images"];
          if (!action)
            throw new Error(
              'Invalid action "case.case:upload-memorial-images".',
            );

          const form = event.currentTarget;
          const formData = new FormData(form);
          formData.delete("text");

          const response = await action.call({
            params: { memorial_id: memorial.id },
            body: formData,
          });

          if (response.ok) {
            const updatedMemorial: Case["memorial"] = await response.json();
            setMemorial(updatedMemorial);
          } else {
            console.error(response);
            throw new Error("uppladdning av minnesrumsbilder");
          }
        }

        return "Presentation uppdaterad.";
      }}
      {...props}
    >
      <Bananas.CardHeader title="Presentation" />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldImage
            formName="image"
            label="Porträtt"
            value={memorial.image}
          />

          <Bananas.CardFieldImage
            formName="header_image"
            label="Omslag"
            value={memorial.header_image}
          />
        </Bananas.CardRow>

        <Bananas.CardRow>
          <Bananas.CardFieldText
            formName="text"
            helperText="En kortare hälsning som visas innan namnet på minnessidor och i listningen. Ungefär fem ord. För information om begravningen eller minnesstunden redigera respektive informationsfält på 'Ärende' sidan."
            label="Hälsning"
            maxLength={40}
            value={memorial.text}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions>
        <Bananas.CardCancelButton />
        <Bananas.CardSaveButton />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};
