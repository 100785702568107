import { Case } from "@/extensions/klarahill/types";
import { Layout } from "@/extensions/obituary/types";

import { Story } from "./shared";

export type templateFn = (c: Case) => Story;

/**
 * Obituaries share the same template.
 * Two column layout is identical, but allows for `splits` in `relatives`.
 */
const obituary: templateFn = (c) => [
  {
    type: "container",
    id: crypto.randomUUID(),
    variant: "header",
    gap: true,
    children: [
      {
        type: "image",
        id: crypto.randomUUID(),
        variant: "top-image",
        src: "gallery/13606.bmp",
        height: 22,
      },

      {
        type: "multiline",
        id: crypto.randomUUID(),
        variant: "greeting",
        text: c.memorial.text,
      },

      {
        type: "singleline",
        id: crypto.randomUUID(),
        variant: "heading",
        text: `${c.deceased.first_name} ${c.deceased.last_name}`,
      },
    ],
  },

  {
    type: "container",
    id: crypto.randomUUID(),
    variant: "deceased",
    children: [
      {
        type: "date",
        id: crypto.randomUUID(),
        variant: "birth-date",
        date: c.deceased.birth_date,
        icon: ":asterisk:",
      },

      {
        type: "multiline",
        id: crypto.randomUUID(),
        variant: "epitaph",
        text: "har lämnat oss i stor sorg och saknad",
      },

      {
        type: "date",
        id: crypto.randomUUID(),
        variant: "deceased-date",
        date: c.deceased.deceased_date,
        icon: ":latin_cross:",
      },
    ],
  },

  {
    type: "container",
    id: crypto.randomUUID(),
    variant: "relatives",
    children: [
      {
        type: "singleline",
        id: crypto.randomUUID(),
        variant: "default",
        text: `${c.customers?.[0]?.first_name}`,
      },
    ],
  },

  {
    type: "container",
    id: crypto.randomUUID(),
    variant: "anthology",
    gap: true,
    children: [
      {
        type: "divider",
        id: crypto.randomUUID(),
        size: "small",
      },
      {
        type: "multiline",
        id: crypto.randomUUID(),
        variant: "poem",
        text: "",
      },
      {
        type: "singleline",
        id: crypto.randomUUID(),
        variant: "author",
        text: "",
      },
    ],
  },

  {
    type: "container",
    id: crypto.randomUUID(),
    variant: "ending",
    gap: true,
    children: [
      {
        type: "divider",
        id: crypto.randomUUID(),
        size: "large",
      },

      {
        type: "multiline",
        id: crypto.randomUUID(),
        variant: "funeral",
        text: `${c.funeral?.information}\n\n${c.reception?.information}`,
      },

      {
        type: "multiline",
        id: crypto.randomUUID(),
        variant: "farewell",
        text: "",
      },

      {
        type: "singleline",
        id: crypto.randomUUID(),
        variant: "url",
        text: "Bidra med ett minne på lovabegravning.se/minnesrum",
      },
    ],
  },
];

const template: Record<Layout, templateFn> = {
  OBITUARY_ONE_COLUMN: obituary,
  OBITUARY_TWO_COLUMN: obituary,
};

export default template;
