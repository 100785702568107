import { useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Case } from "@/extensions/klarahill/types";

export interface ReceptionCardProps extends Bananas.CardProps {
  case: Case;
}

export interface ReceptionCardFormValues {
  text: string;
}

export const ReceptionCard: React.FC<ReceptionCardProps> = ({
  case: c,
  children,
  ...props
}) => {
  const api = Bananas.useApi();
  const [memorialServiceMessage, setMemorialServiceMessage] = useState(
    c.memorial.memorial_service_message,
  );

  return (
    <Bananas.Card<ReceptionCardFormValues>
      isEditable
      {...props}
      onSubmit={async (values) => {
        const action = api.operations["case.case:update-memorial"];
        if (!action)
          throw new Error('Invalid action "case.case:update-memorial".');

        const text = values.text;

        if (memorialServiceMessage !== text) {
          const response = await action.call({
            params: { memorial_id: c.memorial.id },
            body: {
              memorial_service_message: text,
            },
          });

          if (response.ok) {
            const updatedMemorial: Case["memorial"] = await response.json();
            setMemorialServiceMessage(updatedMemorial.memorial_service_message);
          } else {
            console.error(response);
            throw new Error("uppdaterande av leverantörsmeddelande.");
          }
        }

        return "Meddelandet till leverantör uppdaterad.";
      }}
    >
      <Bananas.CardHeader title="Minnesstund" />

      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            multiline
            formName="text"
            label="Meddelande till leverantör"
            minRows={3}
            value={memorialServiceMessage}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>

      <Bananas.CardActions>
        <Bananas.CardCancelButton />
        <Bananas.CardSaveButton />
      </Bananas.CardActions>
    </Bananas.Card>
  );
};
