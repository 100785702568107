import { useState } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { MemorialAttendee } from "@/extensions/klarahill/types";

export interface AttendeeDialogProps {
  type: "change" | "create";
  attendee?: MemorialAttendee;
  onChange: (attendee: Omit<MemorialAttendee, "id">) => void;
}

const AttendeeDialog: React.FC<AttendeeDialogProps> = ({
  attendee,
  onChange,
}) => {
  const [name, setName] = useState(attendee?.name ?? "");
  const [phone, setPhone] = useState(attendee?.phone ?? "");
  const [email, setEmail] = useState(attendee?.email ?? "");
  const [info, setInfo] = useState(attendee?.info ?? "");
  const [count, setCount] = useState(attendee?.count ?? 1);
  const [reserve, setReserve] = useState(attendee?.reserve ?? false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 800,
        mt: 1,
        gap: 4,
      }}
    >
      <Box
        gap={2}
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <Box
          gap={2}
          sx={{ display: "flex", flexDirection: "row", width: "100%" }}
        >
          <TextField
            fullWidth
            multiline
            required
            label="Namn"
            sx={{ flex: 3 }}
            type="text"
            value={name}
            onChange={({ currentTarget: { value: name } }) => {
              setName(name);
              onChange({
                name,
                phone,
                email,
                info,
                count,
                reserve,
              });
            }}
          />
          <TextField
            inputProps={{
              min: 1,
            }}
            label="Antal gäster"
            sx={{ flex: 1 }}
            type="number"
            value={count}
            onChange={({ currentTarget: { value } }) => {
              const count = Number.parseInt(value);
              setCount(count);
              onChange({
                name,
                phone,
                email,
                info,
                count,
                reserve,
              });
            }}
          />
        </Box>
        <Box
          gap={2}
          sx={{ display: "flex", flexDirection: "row", width: "100%" }}
        >
          <TextField
            fullWidth
            required
            label="Telefon"
            sx={{ flex: 1 }}
            type="tel"
            value={phone}
            onChange={({ currentTarget: { value: phone } }) => {
              setPhone(phone);
              onChange({
                name,
                phone,
                email,
                info,
                count,
                reserve,
              });
            }}
          />
          <TextField
            fullWidth
            label="E-post"
            sx={{ flex: 1 }}
            type="email"
            value={email}
            onChange={({ currentTarget: { value: email } }) => {
              setEmail(email);
              onChange({
                name,
                phone,
                email,
                info,
                count,
                reserve,
              });
            }}
          />
        </Box>
        <TextField
          fullWidth
          multiline
          helperText="Allergier och övrig information."
          label="Meddelande"
          type="info"
          value={info}
          onChange={({ currentTarget: { value: info } }) => {
            setInfo(info);
            onChange({
              name,
              phone,
              email,
              info,
              count,
              reserve,
            });
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mx: 0.375,
          }}
        >
          <FormControl>
            <FormLabel>Lista</FormLabel>
            <RadioGroup>
              <FormControlLabel
                checked={!reserve}
                control={<Radio />}
                label="Gäst"
                labelPlacement="end"
                onChange={() => {
                  setReserve(false);
                  onChange({
                    name,
                    phone,
                    email,
                    info,
                    count,
                    reserve: false,
                  });
                }}
              />
              <FormControlLabel
                checked={reserve}
                control={<Radio />}
                label="Reserv"
                labelPlacement="end"
                onChange={() => {
                  setReserve(true);
                  onChange({
                    name,
                    phone,
                    email,
                    info,
                    count,
                    reserve: true,
                  });
                }}
              />
              <FormHelperText sx={{ mx: 0.5 }}>
                Genom att markera anmälan som reserv dyker den inte upp i
                gästlistan och utesluts även från summeringen av antal gäster.
              </FormHelperText>
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AttendeeDialog;
