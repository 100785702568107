import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import { useSnackbar } from "notistack";

import { Case } from "@/extensions/klarahill/types";
import { Ad } from "@/extensions/obituary/types";

import { Nullish } from "@/utils/types";

const useAdsQuery = (caseId: Nullish<Case["id"]>) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const c = useQuery({
    queryKey: ["obituary", "ad", caseId],
    queryFn: async () => {
      if (caseId == null || Number.isNaN(caseId)) return null;

      const action = api.operations["obituary.ad:list"];
      if (!action) throw new Error('No action found for "obituary.ad:list"');

      const response = await action.call({
        query: { case_id: caseId },
      });

      if (response.ok) {
        const ads: Ad[] = await response.json();
        return ads;
      } else {
        enqueueSnackbar(`Kunde inte hämta annons med id ${caseId}.`, {
          variant: "error",
        });
        return null;
      }
    },
  });

  return c;
};

export default useAdsQuery;
