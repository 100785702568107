import { memo, useMemo } from "react";

import { Alert, Grid2 as Grid, Stack, SxProps } from "@mui/material";

import { Poem } from "@/extensions/obituary/types";

import PoemCard from "./PoemCard";

import { Nullish } from "@/utils/types";

const styles: Record<string, SxProps> = {
  root: { minHeight: "90vh", bgcolor: "grey.200" },
  grid: { p: [3, 2] },
  item: { height: "100%", width: "100%" },
  heading: { ml: 1 },
  alert: { width: 420 },
};

export interface PoemLibraryProps {
  poems: Poem[];
  selected: Nullish<Poem>;
  onSelectPoem: (poem: Poem) => void;
}

const PoemLibrary: React.FC<PoemLibraryProps> = ({
  onSelectPoem,
  poems,
  selected,
}) => {
  const hasPoems = useMemo(() => poems.length > 0, [poems]);

  return (
    <Stack sx={styles.root}>
      <Stack gap={4} sx={styles.grid}>
        {hasPoems ? (
          <Grid container spacing={1.5}>
            {poems?.map((poem) => (
              <Grid key={poem.id} size={4} sx={styles.item}>
                <PoemCard
                  poem={poem}
                  selected={selected?.id === poem.id}
                  onClick={onSelectPoem}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Stack direction="row" gap={2} justifyContent="flex-start">
            <Alert severity="info" sx={styles.alert} variant="outlined">
              Inga verser matchade din sökning.
            </Alert>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(PoemLibrary);
