import { useCallback, useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Box, Button, Switch, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

import { Case, Memorial } from "@/extensions/klarahill/types";

import * as object from "@/utils/object";

export interface CreateMemorialCardProps {
  case: Case;
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
  setMemorial: React.Dispatch<React.SetStateAction<Memorial>>;
}

export const CreateMemorialCard: React.FC<CreateMemorialCardProps> = ({
  case: c,
  isSubmitting,
  setIsSubmitting,
  setMemorial,
}) => {
  const api = Bananas.useApi();
  const { navigate } = Bananas.useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const [isDismissed, setIsDismissed] = useState(Boolean(c.dismissed_at));

  /**
   * Create a memorial for the current Case.
   * Will reverse Case dismissal if set.
   */
  const createMemorial = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isSubmitting) return;

      const form = event.currentTarget;
      const formData = new FormData(form);
      const payed = formData.get("marked_as_paid");

      const action = api.operations["case.case:create-memorial"];
      if (!action)
        throw new Error('Invalid action "case.case:create-memorial".');

      setIsSubmitting(true);

      const response = await action.call({
        params: { case_id: c.id },
        body: object.omitNullish({
          marked_as_paid_at: payed === "on" ? new Date().toISOString() : null,
          // These keys have to be present, even though they are not relevant for the `create` request.
          text: "",
        }),
      });

      if (!response.ok) {
        enqueueSnackbar(
          "Fel vid skapande av Minnesrum. Försök igen eller kontakta support om felet kvarstår.",
          { variant: "error" },
        );
        setIsSubmitting(false);
        throw response;
      }

      // Reverse case dismissal, if applicable
      {
        const action = api.operations["case.case:update"];
        if (!action) throw new Error('Invalid action "case.case:update".');

        const response = await action.call({
          params: { case_id: c.id },
          body: { dismissed_at: null }, // Setting `dismissed_at` to `null` undisimsses
        });

        if (!response.ok) {
          enqueueSnackbar(
            "Fel inträffade vid aktiverande av begravningsärende. Hör av dig till support eller försök igen senare.",
            { variant: "error" },
          );
          setIsSubmitting(false);
          throw response;
        }
      }

      const updatedMemorial: Case["memorial"] = await response.json();
      setMemorial(updatedMemorial);
      setIsSubmitting(false);
      setIsDismissed(false);

      enqueueSnackbar("Minnesrum skapat.");
      navigate(`case.case:retrieve`, {
        params: { case_id: c.id },
        query: { tab: "memorial" },
      });
    },
    [
      api,
      c.id,
      c.dismissed_at,
      enqueueSnackbar,
      navigate,
      object.omitNullish,
      setIsDismissed,
      setMemorial,
    ],
  );

  const dismissMemorial = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const action = api.operations["case.case:update"];
      if (!action) throw new Error('Invalid action "case.case:update".');

      const response = await action.call({
        params: { case_id: c.id },
        body: { dismissed_at: new Date().toISOString() },
      });

      if (response.ok) {
        setIsDismissed(true);
        enqueueSnackbar(
          "Minnesrum avfärdades. Begravningsärendet markeras som arkiverat. ",
        );
      } else {
        enqueueSnackbar(
          "Fel vid avfärdande av Minnesrum. Hör av dig till support eller försök igen senare.",
          { variant: "error" },
        );
        throw response;
      }
    },
    [api, c.id, enqueueSnackbar, setIsDismissed],
  );

  return (
    <Bananas.Card>
      <Bananas.CardHeader title="Minnesrum" />

      <Box component={"form"} onSubmit={createMemorial}>
        <Bananas.CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingTop: 1,
          }}
        >
          <Typography variant="body2">
            {isDismissed
              ? "Det här begravningsärendets minnesrum har avfärdats och ärendet är arkiverat. Du kan ångra detta genom att skapa ett minnesrum, vilket gör ärendet aktuellt."
              : "Skapa ett minnesrum för begravningsärendet. Minnesrummet måste manuellt publiceras för att det ska bli synligt på hemsidan."}
          </Typography>

          {!isDismissed && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle2">Betalt minnesrum</Typography>
              <Switch id="marked_as_paid" name="marked_as_paid" size="medium" />
            </Box>
          )}
        </Bananas.CardContent>

        <Bananas.CardActions alwaysVisible>
          {!isDismissed && (
            <Button size="medium" variant="outlined" onClick={dismissMemorial}>
              Avfärda
            </Button>
          )}

          <Button
            size="medium"
            type="submit"
            variant={isDismissed ? "outlined" : "contained"}
          >
            Skapa
          </Button>
        </Bananas.CardActions>
      </Box>
    </Bananas.Card>
  );
};
