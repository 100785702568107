import { useCallback } from "react";
import * as Bananas from "bananas-commerce-admin";

import { Box, Button, Stack, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

import { Case } from "@/extensions/klarahill/types";

export interface BitnetNoticeProps {
  case: Case;
  setCase: React.Dispatch<React.SetStateAction<Case>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BitnetNotice: React.FC<BitnetNoticeProps> = ({
  case: c,
  isSubmitting,
  setIsSubmitting,
  setCase,
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const bitnetSync = useCallback(async () => {
    if (isSubmitting) return;

    const action = api.operations["case.case:bit"];
    if (!action) throw new Error('Invalid action "case.case:bit".');

    setIsSubmitting(true);
    const response = await action.call({ params: { case_id: c.id } });
    if (response.ok) {
      const data = await response.json();
      setCase(data);
      enqueueSnackbar("Minnesrum skapat.");
    } else {
      enqueueSnackbar(
        "Fel vid synkade mot Bitnet. Hör av dig till support eller försök igen senare.",
        { variant: "error" },
      );
    }

    setIsSubmitting(false);
  }, [api, c.id, enqueueSnackbar, setIsSubmitting, setCase]);

  return (
    <Stack direction="row" gap={4} pb={8}>
      <Typography sx={{ color: "grey.600" }} variant="body2">
        Information om begravningsärendet hämtas från Bitnet. Ändringar sker
        direkt i Bitnet. Du måste hämta ny data från Bitnet om du ändrar något
        efter att ärendet har skapats.
      </Typography>

      <Box>
        <Button size="small" variant="outlined" onClick={bitnetSync}>
          Hämta&nbsp;data&nbsp;från&nbsp;Bitnet
        </Button>
      </Box>
    </Stack>
  );
};
