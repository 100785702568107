import { useSearchParams } from "react-router-dom";
import * as Bananas from "bananas-commerce-admin";

import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { FormControlLabel, Switch, TableBody, Typography } from "@mui/material";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { MemorialStatusBadge } from "@/extensions/klarahill/components/MemorialStatusBadge";
import SearchBar from "@/extensions/klarahill/components/SearchBar";
import { ListCase, PaginationResult } from "@/extensions/klarahill/types";

import { dateStringOrNull } from "@/utils/dateStringOrNull";

const memorialStatusText: Record<ListCase["memorial_status"], string> = {
  not_created: "Ej skapat",
  draft: "Utkast", // TODO: Make sure we have a consistent terminology.
  published: "Publicerat",
} as const;

const CaseRow = ({ case: c }: { case: ListCase }) => (
  <Bananas.NavigatingTableRow
    key={c.id}
    route="case.case:retrieve"
    routeParams={{ case_id: c.id }}
  >
    <Bananas.TableCell>
      <MemorialStatusBadge type={c.is_active ? "active" : "archived"} />
    </Bananas.TableCell>
    <Bananas.TableCell>{c.name}</Bananas.TableCell>
    <Bananas.TableCell>{c.advisor}</Bananas.TableCell>
    <Bananas.TableCell>
      {memorialStatusText[c.memorial_status]}
    </Bananas.TableCell>
    <Bananas.TableCell>
      {dateStringOrNull(c.memorial_publish_date)}
    </Bananas.TableCell>
    <Bananas.TableCell>{dateStringOrNull(c.date_created)}</Bananas.TableCell>
    <Bananas.TableCell align="right" sx={{ paddingRight: 3 }}>
      {c.id}
    </Bananas.TableCell>
  </Bananas.NavigatingTableRow>
);

const CasesListPage: Bananas.types.PageComponent<
  PaginationResult<ListCase>
> = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isOnlyMine =
    searchParams.get("only_mine") === "true" ||
    searchParams.get("only_mine") === null;

  return (
    <Bananas.Page defaultTab="active" tabSearchParam="panel_filter">
      {/* TODO preserveSearchParams */}
      <Bananas.Header variant="opaque">
        <Bananas.TitleBar title="Begravningsärenden">
          <FormControlLabel
            control={
              <Switch
                checked={isOnlyMine}
                onChange={() => {
                  setSearchParams((sp) => {
                    return {
                      ...Object.fromEntries(sp.entries()),
                      only_mine: isOnlyMine ? "false" : "true",
                    };
                  });
                }}
              />
            }
            label={
              <Typography color="grey.600" component="label" variant="body2">
                Visa enbart mina ärenden
              </Typography>
            }
            sx={{ flexDirection: "row-reverse" }}
          />

          {/* TODO replace with bcom bar */}
          <SearchBar
            defaultValue={searchParams.get("search") ?? ""}
            placeholder="Sök på namn"
            onSubmit={(value) =>
              setSearchParams((sp) => ({
                ...Object.fromEntries(sp.entries()),
                search: value,
              }))
            }
          />
        </Bananas.TitleBar>

        <Bananas.Tabs
          aria-label="Kontrollera vilka begravningsärendent som syns."
          onChange={(tab) => {
            setSearchParams((sp) => ({
              ...Object.fromEntries(sp.entries()),
              panel_filter: tab,
            }));
          }}
        >
          <Bananas.Tab
            key="default"
            icon={<CreateOutlinedIcon />}
            label="Aktuella"
            value="active"
          />
          <Bananas.Tab
            icon={<Inventory2OutlinedIcon />}
            label="Arkiverade"
            value="archived"
          />
          <Bananas.Tab
            icon={<GridViewOutlinedIcon />}
            label="Alla"
            value="all"
          />
        </Bananas.Tabs>
      </Bananas.Header>

      {data.count !== 0 ? (
        <Bananas.Content layout="fullWidth">
          <Bananas.TableCard>
            <Bananas.Table pagination count={data.count}>
              <Bananas.TableHead>
                <Bananas.TableHeading typographyProps={{ pl: 1.5 }} width={60}>
                  Typ
                </Bananas.TableHeading>
                <Bananas.TableHeading>Namn</Bananas.TableHeading>
                <Bananas.TableHeading>Rådgivare</Bananas.TableHeading>
                <Bananas.TableHeading>Minnesrum</Bananas.TableHeading>
                <Bananas.TableHeading>Publicerat</Bananas.TableHeading>
                <Bananas.TableHeading>Skapat</Bananas.TableHeading>
                <Bananas.TableHeading align="right">
                  Ärendenummer
                </Bananas.TableHeading>
              </Bananas.TableHead>
              <TableBody>
                {data.results?.map((c) => <CaseRow key={c.id} case={c} />)}
              </TableBody>
            </Bananas.Table>
          </Bananas.TableCard>
        </Bananas.Content>
      ) : (
        <ErrorCard
          errorMessage={"Hittade inga begravningsärenden för ditt urval."}
        />
      )}
    </Bananas.Page>
  );
};

export default CasesListPage;
