import React, { useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import * as styles from "@/extensions/klarahill/pages/case/shared";

import { BitnetNotice } from "./BitnetNotice";
import { CaseCard } from "./CaseCard";
import { CausesCard } from "./CausesCard";
import { CreateMemorialCard } from "./CreateMemorialCard";
import { FuneralCard } from "./FuneralCard";
import { MainContactCard } from "./MainContactCard";
import { MemorialCard } from "./MemorialInfoCard";
import { MemorialStatusCard } from "./MemorialStatusCard";
import { MetadataCard } from "./MetadataCard";

export const CaseDetails: React.FC<styles.CaseTabProps> = ({
  case: initialCase,
}) => {
  const [c, setCase] = useState(initialCase);

  const [funeral, setFuneral] = useState(c.funeral);
  const [reception, setReception] = useState(c.reception);
  const [memorial, setMemorial] = useState(c.memorial);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Bananas.Content layout="fixedWidth">
      {isSubmitting ? (
        <Bananas.LoadingScreen />
      ) : (
        <>
          <Bananas.LeftColumn>
            <CaseCard case={c} />
            <MainContactCard case={c} />
            <FuneralCard case={c} funeral={funeral} setFuneral={setFuneral} />
            <MemorialCard
              case={c}
              reception={reception}
              setReception={setReception}
            />
            <BitnetNotice
              case={c}
              isSubmitting={isSubmitting}
              setCase={setCase}
              setIsSubmitting={setIsSubmitting}
            />
          </Bananas.LeftColumn>

          <Bananas.RightColumn>
            <MetadataCard case={c} />

            {memorial != null ? (
              <MemorialStatusCard case={c} memorial={memorial} />
            ) : (
              <CreateMemorialCard
                case={c}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                setMemorial={setMemorial}
              />
            )}

            <CausesCard case={c} setCase={setCase} />
          </Bananas.RightColumn>
        </>
      )}
    </Bananas.Content>
  );
};
