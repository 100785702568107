import {
  Ad,
  layoutName,
  layoutNameShort,
  publicationName,
} from "@/extensions/obituary/types";

function adName(ad: Ad, variant: "short" | "long" = "short") {
  const name = publicationName[ad.publication_abbreviation];
  const layout =
    variant === "short"
      ? layoutNameShort[ad.layout_variant]
      : layoutName[ad.layout_variant];

  return `${name}, ${layout}`;
}

export default adName;
