import React, { memo, ReactElement, useCallback, useState } from "react";

import {
  Box,
  Grid,
  Input,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  Typography,
} from "@mui/material";

import useDebounce from "@/hooks/useDebounce";

export interface SliderProps extends Omit<MuiSliderProps, "onChange"> {
  debounce?: number;
  icon?: ReactElement;
  label?: string;
  value: number;
  onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  debounce,
  icon,
  label,
  value: initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  const debouncedOnChange = useDebounce(onChange, debounce ?? 500);
  onChange = debounce ? debouncedOnChange : onChange;

  const handleSliderChange = useCallback(
    (_: Event, newValue: number | number[]) => {
      setValue(newValue as number);
      onChange(newValue as number);
    },
    [setValue],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value === "" ? 0 : Number(event.target.value);
      setValue(value);
      onChange(value);
    },
    [setValue],
  );

  const handleBlur = useCallback(
    () =>
      setValue((value) => {
        const newValue = value < 0 ? 0 : value > 100 ? 100 : value;
        onChange(newValue);
        return newValue;
      }),
    [setValue],
  );

  return (
    <Box>
      {label != null && (
        <Typography gutterBottom variant="caption">
          {label}
        </Typography>
      )}

      <Grid container alignItems="center" spacing={2}>
        {icon != null && <Grid item>{icon}</Grid>}

        <Grid item xs>
          <MuiSlider
            id="height"
            value={value}
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
            {...props}
          />
        </Grid>

        <Grid item>
          <Input
            endAdornment="mm"
            inputProps={{ step: 10, min: 0, max: 100, type: "number" }}
            size="small"
            sx={{ ml: 1 }}
            value={value}
            onBlur={handleBlur}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(Slider);
