import { Dayjs } from "dayjs";

import { Advisor, Case, PaginationResult } from "@/extensions/klarahill/types";
import { Story } from "@/extensions/obituary/pages/detail/Story";

import { Email, Millimeter, Nullish, Timedate, UUID } from "@/utils/types";

/// Publications

export type Abbreviation = "DN" | "GP" | "LOVA";

export const publicationName: Record<Abbreviation, string> = {
  DN: "Dagens Nyheter",
  GP: "Göteborgs-Posten",
  LOVA: "Lova Begravning",
};

export type Layout = "OBITUARY_ONE_COLUMN" | "OBITUARY_TWO_COLUMN";

export const layoutName: Record<Layout, string> = {
  OBITUARY_ONE_COLUMN: "Begravningsannons, 1 spalt",
  OBITUARY_TWO_COLUMN: "Begravningsannons, 2 spalter",
};

export const layoutNameShort: Record<Layout, string> = {
  OBITUARY_ONE_COLUMN: "1 spalt",
  OBITUARY_TWO_COLUMN: "2 spalter",
};

export interface Publication {
  abbreviation: Abbreviation;
  name: string;
  layouts: Layout[];
  is_active: boolean;
}

export type Publications = Publication[];

/// Deadlines

export interface DeadlineResponse {
  deadline_time: string;
  publication_date: string;
}

export interface DayjsDeadline {
  deadlineTime: Dayjs;
  publicationDate: Dayjs;
}

/// Collections

/**
 * Images are user uploaded symbols.
 */
export interface ObituaryImage {
  id: UUID;
  name: string;
  url: string;
}

export type ObituarySymbolCategory = string;
export type ObituarySymbolTag = string;
export type ObituarySymbolType = "photo" | "logotype" | "clipart" | "unknown";

export interface ObituarySymbolFilter {
  /** Defaults to 100. */
  page_size?: number;
  page?: number;
  search?: string;
  category?: ObituarySymbolCategory;
  type?: ObituarySymbolType;
  tags?: ObituarySymbolTag[];
  has_color?: boolean;
}

/** Should be divisible by 6 in order to have a nice 6-col grid. */
export const OBITUARY_SYMBOLS_PAGE_SIZE = 102;

/**
 * Symbols are the library/clipart.
 */
export interface ObituarySymbol {
  id: number;
  title: string;
  file_name: string;
  file_url: string;
  category: ObituarySymbolCategory;
  tags: ObituarySymbolTag[];
  type: ObituarySymbolType;
  reference: number;
  has_color: boolean;
}

export type ObituarySymbolOrImage = ObituarySymbol | ObituaryImage;

export interface SymbolOptionResponse {
  toplist: ObituarySymbol[];
  categories: ObituarySymbolCategory[];
  types: ObituarySymbolType[];
}

export type SymbolListResponse = PaginationResult<ObituarySymbol>;

export interface PoemFilter {
  /** Defaults to 100. */
  page_size?: number;
  page?: number;
  search?: string;
}

/** Should be divisible by 6 in order to have a nice 6-col grid. */
export const POEMS_PAGE_SIZE = 102;

export type PoemListResponse = PaginationResult<Poem>;

export interface Poem {
  id: number;
  text: string;
  author?: string;
  reference: number;
}

export interface Emoticon {
  code: string;
  name: string;
  char: string;
}

/// Ads

export interface Document {
  publication: Publication["abbreviation"];
  layout: Layout;
  story: Story;
}

export interface Deadline {
  publication_date: Timedate;
  editing_deadline: Timedate;
}

export interface CreateAdPayload {
  case_id: Case["id"];
  document: Document;
  deadline?: Deadline;
}

export type AdStatus =
  | "draft"
  | "approved"
  | "pending"
  | "confirmed"
  | "rejected";

export interface Ad {
  id: number;
  price: number;
  width: Millimeter;
  height: Millimeter;
  creator: Advisor["name"];

  story: Story;
  preview_url: string;
  layout_variant: Layout;
  publication_abbreviation: Abbreviation;

  printing_original_url: Nullish<string>;
  approved_at: Nullish<Timedate>;
  order_reference: Nullish<string>;
  order_created_at: Nullish<Timedate>;
  order_confirmed_at: Nullish<Timedate>;

  editing_deadline: Nullish<Timedate>;
  publication_date: Nullish<Timedate>;

  rejected_at: Nullish<Timedate>;
  rejected_reason: Nullish<string>;

  status: AdStatus;
}

export interface UpdateAdPayload {
  approve: boolean;
  document: Document;
  deadline: Deadline;
}

export interface ObituaryPreviewPayload {
  publication: Abbreviation;
  layout: Layout;
  story: Story;
}

export type PreviewFormat = "svg" | "pdf" | "png";

export interface ProofPayload {
  obituary_ad_ids: Ad["id"][];
  recipients: Email[];
}

export interface PatchAdPayload {
  approve?: boolean;
  document?: Document;
  deadline?: Deadline;
}
