import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Button, TableBody } from "@mui/material";

import { useSnackbar } from "notistack";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { OrderRow } from "@/extensions/klarahill/components/OrderRow";
import { Case, Order, PaginationResult } from "@/extensions/klarahill/types";

const ERROR_MESSAGE =
  "Fel vid hämtande av ordrar. Försök igen eller kontakta support om felet kvarstår.";

export const Orders = ({ case: { id } }: { case: Case }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const orders = useQuery({
    queryKey: ["case", "c", id, "orders"],
    queryFn: async ({ signal }) => {
      const action = api.operations["order.contrib:list-case-orders"];
      if (!action)
        throw new Error('No action found for "order.contrib:list-case-orders"');

      const response = await action.call({
        params: { case_id: id },
      });

      if (signal?.aborted) throw new Error("Query aborted.");

      if (response.ok) {
        const newOrders: PaginationResult<Order> = await response.json();
        return newOrders;
      } else {
        enqueueSnackbar(ERROR_MESSAGE, { variant: "error" });
        throw response;
      }
    },
  });

  if (orders.error) {
    enqueueSnackbar(ERROR_MESSAGE, { variant: "error" });
    throw orders.error;
  }

  const noOrders = orders.data != null && orders.data.count === 0;

  const downloadFlowerReports = () => {
    const operation = api.operations["order.contrib:case-flowers-pdf"];
    if (!operation) {
      throw new Error('No action found for "order.contrib:case-flowers-pdf"');
    }

    return operation.url({ params: { case_id: id } }).href;
  };

  return orders.isFetching ? (
    <Bananas.LoadingScreen label="Hämtar ordrar…" />
  ) : noOrders ? (
    <Bananas.Content layout="fullWidth">
      <ErrorCard errorMessage="Det finns inga blomsterordrar för det här begravningsärendet." />
    </Bananas.Content>
  ) : (
    <Bananas.ContentWrapperWithActionBar>
      <Bananas.Content layout="fullWidth">
        <Bananas.TableCard>
          <Bananas.Table count={orders.data?.count ?? 0}>
            <Bananas.TableHead>
              <Bananas.TableHeading width={60}>Typ</Bananas.TableHeading>
              <Bananas.TableHeading>Namn</Bananas.TableHeading>
              <Bananas.TableHeading>Epost</Bananas.TableHeading>
              <Bananas.TableHeading>Begravningsärende</Bananas.TableHeading>
              <Bananas.TableHeading>Bekräftat den</Bananas.TableHeading>
              <Bananas.TableHeading align="right">
                Ordernummer
              </Bananas.TableHeading>
            </Bananas.TableHead>

            <TableBody>
              {orders.data?.results?.map((order, i) => (
                <OrderRow key={i} order={order} />
              ))}
            </TableBody>
          </Bananas.Table>
        </Bananas.TableCard>
      </Bananas.Content>

      <Bananas.ActionBar>
        <Button
          color="primary"
          href={downloadFlowerReports()}
          startIcon={<SaveAltOutlinedIcon />}
          variant="outlined"
        >
          Ladda ned hälsningar
        </Button>
      </Bananas.ActionBar>
    </Bananas.ContentWrapperWithActionBar>
  );
};
