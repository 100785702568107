import { memo, useCallback, useEffect, useRef, useState } from "react";

import EmojiSymbolsIcon from "@mui/icons-material/EmojiSymbols";
import {
  Box,
  IconButton,
  SxProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";

import { Emoticon } from "@/extensions/obituary/types";

import useDebounce from "@/hooks/useDebounce";

import EmotionPicker from "./EmoticonPicker";

import { Nullish } from "@/utils/types";

const styles: Record<string, SxProps> = {
  textField: {
    width: "100%",
    bgcolor: "white !important",

    "& .MuiFilledInput-root": {
      bgcolor: "white !important",
      border: "1px solid",
      borderColor: "grey.400",
    },
  },

  emoticonButton: {
    position: "absolute",
    right: 6,
    bottom: 6,
    bgcolor: "background.paper",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "grey.400",
    transform: "scale(0.7)",
    zIndex: 1,

    "&:hover": { borderColor: "black" },
  },
};

type CursorPosition = Nullish<number>;

export type TextFieldProps = Omit<MuiTextFieldProps, "onChange" | "value"> & {
  debounce?: number;
  onChange: (value: string) => void;
  emoticons?: Emoticon[];
  closeEmoticonPickerOnSelect?: boolean;
  value: Nullish<string>;
};

const TextField: React.FC<TextFieldProps> = ({
  closeEmoticonPickerOnSelect,
  debounce,
  disabled,
  onChange,
  value: initialValue,
  sx,
  emoticons,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(initialValue ?? "");
  const anchorRef = useRef<HTMLDivElement>(null);

  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const [cursorPosition, setCursorPosition] = useState<CursorPosition>(null);

  const debouncedOnChange = useDebounce(onChange, debounce ?? 500);
  const shouldDebounce = debounce != null && onChange != null;
  onChange = shouldDebounce ? debouncedOnChange : onChange;

  const hasEmoticons = Array.isArray(emoticons) && emoticons.length > 0;
  const [refocusTextFieldAt, setRefocusTextFieldAt] =
    useState<CursorPosition>(null);

  // Get the cursor position for the emoticon picker.
  useEffect(() => {
    if (!hasEmoticons) return;

    const textField = textFieldRef.current;
    if (textField == null) return;

    const handleSelectionChange = () => {
      setCursorPosition(textField.selectionStart);
    };

    textField.addEventListener("select", handleSelectionChange);
    textField.addEventListener("click", handleSelectionChange);
    textField.addEventListener("keyup", handleSelectionChange);

    return () => {
      textField.removeEventListener("select", handleSelectionChange);
      textField.removeEventListener("click", handleSelectionChange);
      textField.removeEventListener("keyup", handleSelectionChange);
    };
  }, []);

  const handleOnChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setValue(value);
      onChange?.(value);
    },
    [onChange],
  );

  const handleSetOpen = useCallback(() => setOpen((prev) => !prev), []);

  const handleEmoticonClick = useCallback(
    (emoticon: Emoticon) =>
      setValue((value) => {
        if (closeEmoticonPickerOnSelect) setOpen(false);

        const text =
          cursorPosition != null
            ? `${value?.slice(0, cursorPosition) ?? ""} ${emoticon.code} ${value?.slice(cursorPosition) ?? ""}`
            : `${value} ${emoticon.code}`;

        onChange?.(text);

        // Refocusing has to happen _after_ next render.
        if (textFieldRef.current != null && cursorPosition != null) {
          setRefocusTextFieldAt(cursorPosition + emoticon.code.length + 2);
        }

        return text;
      }),
    [cursorPosition, onChange, setValue],
  );

  // Refocus text field after inserting a emoticon.
  // Has to take place the render after insertion.
  useEffect(() => {
    if (refocusTextFieldAt == null) return;

    const textField = textFieldRef.current;
    if (textField == null) return;

    textField.focus();
    textField.setSelectionRange(refocusTextFieldAt, refocusTextFieldAt);
    setRefocusTextFieldAt(null);
  }, [refocusTextFieldAt]);

  return (
    <Box sx={{ position: "relative" }}>
      <MuiTextField
        {...props}
        disabled={disabled}
        inputRef={textFieldRef}
        // @ts-expect-error - Incorrect sx type
        sx={{
          ...styles.textField,
          ...sx,
          cursor: disabled ? "not-allowed" : "text",
        }}
        value={value}
        onChange={handleOnChange}
      />

      {hasEmoticons && (
        <>
          {/* @ts-expect-error - Incorrect sx type */}
          <IconButton
            ref={anchorRef}
            disableRipple
            sx={styles.emoticonButton}
            tabIndex={-1}
            onClick={handleSetOpen}
          >
            <EmojiSymbolsIcon />
          </IconButton>

          <EmotionPicker
            anchor={anchorRef}
            emoticons={emoticons}
            open={open}
            onClickAway={handleSetOpen}
            onEmoticonClick={handleEmoticonClick}
          />
        </>
      )}
    </Box>
  );
};

export default memo(TextField);
