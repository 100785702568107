import * as Bananas from "bananas-commerce-admin";

import ExportFloristOrdersCard from "./ExportFloristOrdersCard";

const ReportPage: Bananas.types.PageComponent = () => {
  const api = Bananas.useApi();

  return (
    <Bananas.Page>
      <Bananas.Header>
        <Bananas.TitleBar title="Rapporter" />
      </Bananas.Header>

      <Bananas.Content layout="fixedWidth">
        <Bananas.LeftColumn>
          {api.operations["report.order:create"] && <ExportFloristOrdersCard />}
        </Bananas.LeftColumn>
      </Bananas.Content>
    </Bananas.Page>
  );
};

export default ReportPage;
