import { useCallback } from "react";
import * as Bananas from "bananas-commerce-admin";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Stack, TableBody, TableRow } from "@mui/material";

import { MemorialAttendee } from "@/extensions/klarahill/types";

import * as format from "@/utils/format";
import { Nullish } from "@/utils/types";

export interface IconButtonRowProps {
  buttons: Nullish<{ icon: React.ReactNode; onClick: () => void }>[];
}

export const IconButtonRow: React.FC<IconButtonRowProps> = ({ buttons }) => (
  <Stack
    direction="row"
    sx={{
      px: 0.25,
      border: "solid 1px grey.600",
      borderRadius: 1,
      "& > :last-child": { border: "none" },
      "& *": { fill: "solid 1px grey.600" },
    }}
  >
    {buttons
      .filter((button): button is NonNullable<typeof button> => button != null)
      .map(({ icon, onClick }, i) => (
        <Box
          key={i}
          px={0.75}
          py={0.25}
          sx={{
            boxSizing: "content-box",
            height: 18,
            borderRight: "solid 1px grey.600",
            "& svg": { width: "0.75em", height: "0.75em" },
          }}
          onClick={onClick}
        >
          {icon}
        </Box>
      ))}
  </Stack>
);

export interface AttendeesProps {
  icon?: React.ReactNode;
  title: string;
  attendees: MemorialAttendee[];
  type: "guest" | "reserve";
  onDelete?: (id: number) => void;
  onMakeGuest?: (id: number) => void;
  onEdit?: (id: number) => void;
}

export const Attendees: React.FC<AttendeesProps> = ({
  attendees,
  title,
  icon,
  onDelete,
  onEdit,
  onMakeGuest,
  type,
}) => {
  const handleClick = useCallback((id: number) => onEdit?.(id), [onEdit]);

  return (
    <Bananas.TableCard>
      <Bananas.Table count={attendees.length}>
        <Bananas.TableHead icon={icon} title={title}>
          <Bananas.TableHeading width="20%">Namn</Bananas.TableHeading>
          <Bananas.TableHeading width="30%">Telefon</Bananas.TableHeading>
          <Bananas.TableHeading width="50%">Meddelande</Bananas.TableHeading>
          <Bananas.TableHeading align="right" sx={{ width: "0" }}>
            Antal
          </Bananas.TableHeading>
          <Bananas.TableHeading align="right" sx={{ width: "0" }} />
        </Bananas.TableHead>

        <TableBody>
          {attendees.map((attendee) => {
            return (
              <TableRow key={attendee.id} hover sx={{ cursor: "pointer" }}>
                <Bananas.TableCell onClick={() => handleClick(attendee.id)}>
                  {attendee.name}
                </Bananas.TableCell>
                <Bananas.TableCell onClick={() => handleClick(attendee.id)}>
                  {format.swedishPhoneNumber(attendee.phone) ?? attendee.phone}
                </Bananas.TableCell>
                <Bananas.TableCell onClick={() => handleClick(attendee.id)}>
                  {attendee.info}
                </Bananas.TableCell>
                <Bananas.TableCell
                  align="right"
                  onClick={() => handleClick(attendee.id)}
                >
                  {attendee.count}
                </Bananas.TableCell>

                <Bananas.TableCell>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                  >
                    <IconButtonRow
                      buttons={[
                        type == "reserve"
                          ? {
                              icon: (
                                <FileDownloadOutlinedIcon
                                  sx={{ transform: "rotate(180deg)" }}
                                />
                              ),
                              onClick: () => onMakeGuest?.(attendee.id),
                            }
                          : null,
                        {
                          icon: <DeleteOutlineOutlinedIcon />,
                          onClick: () => onDelete?.(attendee.id),
                        },
                      ]}
                    />
                  </Stack>
                </Bananas.TableCell>
              </TableRow>
            );
          })}

          {Boolean(type === "guest" && attendees.length > 0) && (
            <Bananas.TableSummary columns={5}>
              Gäster totalt&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>{attendees.reduce((a, b) => a + b.count, 0)}</strong>
            </Bananas.TableSummary>
          )}
        </TableBody>
      </Bananas.Table>
    </Bananas.TableCard>
  );
};

export default Attendees;
