import * as Bananas from "bananas-commerce-admin";

import { Case } from "@/extensions/klarahill/types";

export interface CaseCardProps extends Bananas.CardProps {
  case: Case;
}

export const CaseCard: React.FC<CaseCardProps> = ({
  case: { deceased: { first_name, last_name, birth_date, deceased_date } = {} },
  ...props
}) => (
  <Bananas.Card {...props}>
    <Bananas.CardHeader title="Begravningsärende" />
    <Bananas.CardContent>
      <Bananas.CardRow>
        <Bananas.CardFieldText
          formName="fullName"
          label="Namn"
          value={`${first_name} ${last_name}`}
        />
      </Bananas.CardRow>

      <Bananas.CardRow>
        <Bananas.CardFieldText
          formName="birth_date"
          label="Född"
          value={birth_date}
        />

        <Bananas.CardFieldText
          formName="deceased_date"
          label="Avliden"
          value={deceased_date}
        />
      </Bananas.CardRow>
    </Bananas.CardContent>
  </Bananas.Card>
);
