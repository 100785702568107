import { useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { useSnackbar } from "notistack";

import type { Message } from "@/extensions/klarahill/types";

import Comments from "./Comments";
import Field from "./Field";

export interface MessageCardProps {
  initialMessage: Message;
}

const MessageCard: React.FC<MessageCardProps> = ({ initialMessage }) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState(initialMessage);
  const { text, author, date_created, image, id, status, comments } = message;
  const hasComments = Array.isArray(comments) && comments.length > 0;

  const date = new Date(date_created);

  const updateStatus = async () => {
    const action = api.operations["case.case:set-message-status"];
    if (!action)
      throw new Error('Invalid action "case.case:set-message-status".');

    const response = await action.call({
      params: { message_id: id },
      body: { status: status === "published" ? "removed" : "published" },
    });

    if (response.ok) {
      enqueueSnackbar("Status på minne uppdaterad.");
      const updatedMessage: Message = await response.json();
      // HACK: Doesn't seem like the response includes the comments, so we just keep the old ones.
      updatedMessage.comments = message.comments;
      setMessage(updatedMessage);
    } else {
      enqueueSnackbar("Fel vid uppdaterande av meddelandestatus.", {
        variant: "error",
      });
      throw response;
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        borderRadius: "calc(3 * var(--mui-shape-borderRadius)) !important",
        overflow: "hidden",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ py: 1, px: 3 }}>
        <Typography sx={{ marginY: "auto", flexGrow: 1 }}>
          {author}, text {image ? "och bild" : ""}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Stack gap={3} pb={3} px={3}>
          <Stack
            alignItems="center"
            direction="row"
            gap={3}
            justifyContent="space-between"
          >
            <Field label="Namn" value={author} />

            <FormControlLabel
              control={
                <Switch
                  checked={status === "published"}
                  name="visibilitySwitch"
                  onChange={updateStatus}
                />
              }
              label={
                <Typography
                  component="label"
                  htmlFor="visibilitySwitch"
                  variant="body1"
                >
                  Synlig
                </Typography>
              }
              sx={{ flexDirection: "row-reverse" }}
            />
          </Stack>

          <Field label="Datum" value={date.toLocaleDateString()} />
          <Field label="Text" value={text} />

          {image && (
            <Field isImage author={author} label="Bild" value={image} />
          )}
        </Stack>

        {hasComments && <Comments comments={comments} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default MessageCard;
