import { memo } from "react";

import { Box, BoxProps } from "@mui/material";

export interface ToolbarProps extends BoxProps {
  children?: React.ReactNode;
}

const Toolbar: React.FC<ToolbarProps> = ({ children, sx, ...props }) => (
  <Box
    component="menu"
    sx={{
      p: 0,
      m: 0,
      cursor: "pointer",
      listStyle: "none",
      "& li": { display: "contents" },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
);

export default memo(Toolbar);
