import { useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { Button } from "@mui/material";

import { useSnackbar } from "notistack";

import * as styles from "@/extensions/klarahill/pages/case/shared";
import { Case, MemorialAttendee } from "@/extensions/klarahill/types";

import AttendeeDialog from "./AttendeeDialog";
import Attendees from "./Attendees";
import { InformationCard } from "./InformationCard";
import { ReceptionCard } from "./ReceptionCard";
import SendEmailDialog from "./SendEmailDialog";

import * as format from "@/utils/format";

export const Reception: React.FC<styles.CaseTabProps> = ({ case: c }) => {
  const openDialog = Bananas.useDialog();
  const { enqueueSnackbar } = useSnackbar();

  const [reception, setReception] = useState(c.reception);
  const [attendees, setAttendees] = useState(c.memorial_service_attendees);

  const api = Bananas.useApi();

  const mainContact = c.customers?.find(
    ({ is_main_contact }) => is_main_contact,
  );

  const downloadAttendeesURL = api.operations[
    "case.case:get-memorial-service-pdf"
  ]?.url({
    params: { case_id: c.id },
  });

  const openAttendeesEmailDialog = async () => {
    const action =
      api.operations["case.case:send-memorial-service-attendees-email"];
    if (!action)
      throw new Error(
        'Invalid action "case.case:send-memorial-service-attendees-email".',
      );

    let recipient = mainContact!.email;
    if (
      await openDialog(
        "Skicka gästlista",
        <SendEmailDialog
          mainContact={mainContact!}
          onChange={(value) => (recipient = value)}
        />,
        {
          ok: "Skicka",
          cancel: "Avbryt",
          dialogProps: { fullWidth: true, maxWidth: "sm" },
        },
      )
    ) {
      const response = await action.call({
        params: { case_id: c.id },
        body: { recipient },
      });
      if (response.ok) {
        enqueueSnackbar(
          `Ett mail med gästlistan skickades till ${recipient}.`,
          { variant: "success" },
        );
      } else {
        enqueueSnackbar(
          `Misslyckades med att skicka gästlista till ${recipient}.`,
          { variant: "error" },
        );
        throw response;
      }
    }
  };

  const openEditAttendeeDialog = async (id: number) => {
    const action = api.operations["case.case:update-reception"];
    if (!action)
      throw new Error('Invalid action "case.case:update-reception".');

    let attendee = attendees.find((attendee) => attendee.id === id);
    if (!attendee) throw new Error("Attendee not found.");
    // 😖💀🗑️
    let changes;

    if (
      await openDialog(
        `Ändra ${attendee.name}`,
        <AttendeeDialog
          attendee={attendee}
          type="change"
          onChange={(value) => {
            changes = { ...value, id: attendee!.id };
            attendee = { ...attendee!, ...value };
          }}
        />,
        {
          ok: "Spara",
          cancel: "Avbryt",
          dialogProps: { fullWidth: true, maxWidth: "md" },
        },
      )
    ) {
      if (changes == null) return;

      // @ts-expect-error - c as bullshit
      if (changes?.email === "") delete changes.email;
      // @ts-expect-error - c as bullshit
      if (changes?.phone === "") delete changes.email;

      const response = await action.call({
        params: { case_id: c.id },
        body: { attendees: [changes] },
      });

      if (response.ok) {
        const updatedReception: Case["reception"] = await response.json();
        setReception(updatedReception);

        const newAttendees = structuredClone(attendees);
        const index = newAttendees.findIndex((attendee) => attendee.id === id);
        newAttendees[index] = attendee;
        setAttendees(newAttendees);
        enqueueSnackbar(
          `Uppdaterade ${format.genetive(attendee.name)} minnestundsanmälan.`,
          { variant: "success" },
        );
      } else {
        enqueueSnackbar(
          `Misslyckades med att uppdatera ${format.genetive(attendee.name)} minnestundsanmälan.`,
          { variant: "error" },
        );
        throw response;
      }
    }
  };

  const openCreateAttendeeDialog = async () => {
    const action = api.operations["case.case:create-attendee"];
    if (!action) throw new Error('Invalid action "case.case:create-attendee".');

    let attendee;
    if (
      await openDialog(
        "Skapa ny gäst till minnesstund",
        <AttendeeDialog
          type="create"
          onChange={(value) => {
            attendee = value;
          }}
        />,
        {
          ok: "Skapa",
          cancel: "Avbryt",
          dialogProps: { fullWidth: true, maxWidth: "md" },
        },
      )
    ) {
      if (attendee == null) return;

      const response = await action.call({
        params: { case_id: c.id },
        body: attendee,
      });

      if (response.ok) {
        const updatedAttendees: MemorialAttendee[] = await response.json();
        setAttendees(updatedAttendees);

        enqueueSnackbar(`Skapade minnesstundsanmälan.`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Misslyckades med att skapa minnestundsanmälan.`, {
          variant: "error",
        });
        throw response;
      }
    }
  };

  const makeReserveToGuest = async (id: number) => {
    const action = api.operations["case.case:update-reception"];
    if (!action)
      throw new Error('Invalid action "case.case:update-reception".');

    const index = attendees.findIndex((v) => v.id === id);
    const attendee = attendees?.[index];
    if (attendee == null) throw new Error("Attendee not found.");

    const response = await action.call({
      params: { case_id: c.id },
      body: { attendees: [{ id, reserve: false }] },
    });

    if (response.ok) {
      const updatedReception: Case["reception"] = await response.json();
      setReception(updatedReception);

      const newAttendees = structuredClone(attendees);
      newAttendees[index]!.reserve = false;
      setAttendees(newAttendees);
      enqueueSnackbar(
        `Uppdaterade synligheten för ${format.genetive(attendee.name)} minnestundsanmälan.`,
        { variant: "success" },
      );
    } else {
      enqueueSnackbar(
        `Misslyckades med att uppdatera synligheten för ${format.genetive(
          attendee.name,
        )} minnestundsanmälan.`,
        { variant: "error" },
      );
      throw response;
    }
  };

  const deleteAttendee = async (id: number) => {
    const action = api.operations["case.attendee:delete"];
    if (!action) throw new Error('Invalid action "case.case:delete-attendee".');

    const index = attendees.findIndex((v) => v.id === id);
    const attendee = structuredClone(attendees[index]);
    if (attendee == null) throw new Error("Attendee not found.");

    const response = await action.call({
      params: { case_id: c.id, attendee_id: id },
    });

    if (response.ok) {
      const newAttendees = structuredClone(attendees);
      delete newAttendees[index];
      setAttendees(newAttendees);
      enqueueSnackbar(
        `Tog bort för ${format.genetive(attendee.name)} minnestundsanmälan.`,
        {
          variant: "success",
        },
      );
    } else {
      enqueueSnackbar(
        `Misslyckades med att uppdatera synligheten för ${format.genetive(
          attendee.name,
        )} minnestundsanmälan.`,
        {
          variant: "error",
        },
      );
      throw response;
    }
  };

  return (
    <Bananas.ContentWrapperWithActionBar>
      <Bananas.Content layout="fixedWidth">
        <Bananas.LeftColumn>
          <ReceptionCard case={c} sx={{ minHeight: 255 }} />
        </Bananas.LeftColumn>

        <Bananas.RightColumn>
          <InformationCard
            case={c}
            reception={reception}
            setReception={setReception}
          />
        </Bananas.RightColumn>

        <Bananas.TwoColumn>
          <Attendees
            attendees={attendees.filter((v) => !v.reserve)}
            icon={<Groups2OutlinedIcon />}
            title="Gästlista"
            type="guest"
            onDelete={deleteAttendee}
            onEdit={openEditAttendeeDialog}
          />

          <Attendees
            attendees={attendees.filter((v) => v.reserve)}
            icon={<HourglassEmptyRoundedIcon />}
            title="Reservlista"
            type="reserve"
            onDelete={deleteAttendee}
            onEdit={openEditAttendeeDialog}
            onMakeGuest={makeReserveToGuest}
          />
        </Bananas.TwoColumn>
      </Bananas.Content>

      <Bananas.ActionBar>
        <Button
          startIcon={<PersonAddOutlinedIcon />}
          variant="outlined"
          onClick={openCreateAttendeeDialog}
        >
          Skapa gäst
        </Button>

        {downloadAttendeesURL && (
          <Button
            href={downloadAttendeesURL.href}
            startIcon={<SimCardDownloadOutlinedIcon />}
            target="_blank"
            variant="outlined"
          >
            Ladda ned gästlista
          </Button>
        )}

        <Button
          startIcon={<MailOutlineIcon />}
          variant="outlined"
          onClick={openAttendeesEmailDialog}
        >
          Skicka gästlista
        </Button>
      </Bananas.ActionBar>
    </Bananas.ContentWrapperWithActionBar>
  );
};
