import { memo } from "react";
import * as Bananas from "bananas-commerce-admin";

import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Button } from "@mui/material";

import { useObituaryDialog } from "@/extensions/obituary/contexts/ObituaryDialogContext";

const Actions = () => {
  const { openDialog } = useObituaryDialog();

  return (
    <Bananas.ActionBar>
      <Button
        startIcon={<MailOutlineIcon />}
        variant="outlined"
        onClick={openDialog?.("proof")}
      >
        Skicka korrektur
      </Button>

      <Button
        startIcon={<NewspaperIcon />}
        variant="outlined"
        onClick={openDialog?.("publish")}
      >
        Boka annonser
      </Button>

      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={openDialog?.("create")}
      >
        Skapa ny annons
      </Button>
    </Bananas.ActionBar>
  );
};

export default memo(Actions);
