import { useMemo } from "react";
import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import { useSnackbar } from "notistack";

import {
  Layout,
  layoutName,
  Publication,
  Publications,
} from "@/extensions/obituary/types";

import { Nullish, Option } from "@/utils/types";

const usePublicationsQuery = (
  publication: Nullish<Publication["abbreviation"]>,
) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  /** List of available publications. */
  const publications = useQuery({
    queryKey: ["obituary", "publications"],
    queryFn: async () => {
      const action = api.operations["obituary.publication:list"];
      if (!action)
        throw new Error('Invalid action "obituary.publication:list".');

      const response = await action.call();
      if (response.ok) {
        const publications: Publications = await response.json();
        return publications;
      } else {
        enqueueSnackbar("Kunde inte hämta tidningar.", { variant: "error" });
        return null;
      }
    },
  });

  /** Mapping of publication to layouts. */
  const layouts = useMemo(() => {
    const layouts: Partial<Record<Publication["abbreviation"], Layout[]>> = {};
    for (const publication of publications?.data ?? []) {
      layouts[publication.abbreviation] = publication.layouts;
    }
    return layouts;
  }, [publications]);

  const options = useMemo<Nullish<Option[]>>(() => {
    return (
      publications.data
        ?.map(({ abbreviation, is_active, name }) => ({
          label: name,
          value: abbreviation,
          disabled: !is_active,
        }))
        // sort first by disalbed and name
        ?.sort((a, b) => {
          if (a.disabled === b.disabled) return a.label.localeCompare(b.label);
          return a.disabled ? 1 : -1;
        })
    );
  }, [publications]);

  const hasAllData =
    publications.isSuccess && options != null && layouts != null;

  const layoutOptions = useMemo(() => {
    if (publication == null) return null;
    return layouts[publication]?.map((layout) => ({
      label: layoutName[layout],
      value: layout,
    }));
  }, [publication, layouts]);

  return { hasAllData, layouts, layoutOptions, options, ...publications };
};

export default usePublicationsQuery;
