import { useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

import type { Comment } from "@/extensions/klarahill/types";

import Field from "./Field";

const stringOrFallback = (s: string, fallback: string) =>
  s.trim() === "" ? fallback : s;

export interface CommentProps {
  comment: Comment;
}

const Comment: React.FC<CommentProps> = ({
  comment: { author, text, date_created, email, id, status: commentStatus },
}) => {
  const [status, setStatus] = useState(commentStatus);
  const dateCreated = new Date(date_created);
  const { enqueueSnackbar } = useSnackbar();
  const api = Bananas.useApi();

  const updateStatus = async () => {
    const action = api.operations["case.case:set-comment-status"];
    if (!action)
      throw new Error('Invalid action "case.case:set-comment-status".');

    const newStatus = status === "published" ? "removed" : "published";

    const response = await action.call({
      params: { comment_id: id },
      body: { status: newStatus },
    });

    if (response.ok) {
      enqueueSnackbar("Status på kommentar uppdaterad.");
      setStatus(newStatus);
    } else {
      enqueueSnackbar("Fel vid uppdaterande av kommentarstatus.", {
        variant: "error",
      });
      throw response;
    }
  };

  return (
    <Stack gap={2}>
      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        justifyContent="space-between"
      >
        <Field label="Namn" value={author} />

        <FormControlLabel
          control={
            <Switch
              checked={status === "published"}
              name="visibilitySwitch"
              onChange={updateStatus}
            />
          }
          label={
            <Typography
              component="label"
              htmlFor="visibilitySwitch"
              variant="body1"
            >
              Synlig
            </Typography>
          }
          sx={{ flexDirection: "row-reverse" }}
        />
      </Stack>

      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        sx={{ "> *": { flex: 1 } }}
      >
        <Field label="E-post" value={stringOrFallback(email, "–")} />
        <Field label="Upplagt den" value={dateCreated.toLocaleDateString()} />
      </Stack>

      <Field label="Text" value={text} />
    </Stack>
  );
};

export default Comment;
