import { useMemo } from "react";

import { Divider, Stack, Typography } from "@mui/material";

import type { Comment as TComment } from "@/extensions/klarahill/types";

import Comment from "./Comment";

export interface CommentsProps {
  comments: TComment[];
}

const Comments: React.FC<CommentsProps> = ({ comments }) => {
  const hasComments = Array.isArray(comments) && comments.length > 0;

  const title = useMemo(() => {
    if (!hasComments) return "Inga kommentarer";
    const count = comments.length;
    return count === 1 ? "1 Kommentar" : `${count} Kommentarer`;
  }, [comments?.length]);

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: "grey.50",
        ...theme.applyStyles("dark", {
          backgroundColor: "#151515",
        }),
        borderTop: "1px solid",
        borderColor: "divider",
        p: 3,
      })}
    >
      <Typography sx={{ fontWeight: 500, mb: 3 }} variant="body1">
        {title}
      </Typography>

      <Stack>
        {hasComments &&
          comments
            .flatMap((comment) => [
              <Comment key={`comment-${comment.id}`} comment={comment} />,
              <Divider key={`divider-${comment.id}`} sx={{ marginY: 2 }} />,
            ])
            .slice(0, -1)}
      </Stack>
    </Stack>
  );
};

export default Comments;
