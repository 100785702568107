import { memo, useCallback, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  IconButton,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";

const styles: Record<string, SxProps> = {
  root: {
    position: "relative",
    justifyContent: "center",
    ml: 2,
    width: "100%",
    maxWidth: 420,
  },

  button: {
    position: "absolute",
    zIndex: 1,
    ml: 2,
  },

  input: {
    bgcolor: "background.default",
    border: "1px solid",
    borderColor: "divider",
    borderRadius: 12,
    width: "100%",
    maxWidth: 600,
    pl: 2,

    "& .MuiOutlinedInput-root": {
      width: "100%",
      pl: 2,
      "& input": { pl: 5 },
      "& fieldset": { border: 0 },
      "&.Mui-focused fieldset": {
        border: 0,
        borderColor: "secondary.main",
      },
    },
  },
};

export interface SearchBarProps
  extends Omit<TextFieldProps, "onChange" | "onSubmit"> {
  defaultValue?: string;
  onChange?: (inputs: string) => void;
  onSubmit?: (inputs: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  defaultValue,
  onChange,
  onSubmit,
  variant,
  sx,
  ...props
}) => {
  const [input, setInput] = useState(defaultValue ?? "");

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setInput(value);
      onChange?.(value);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onSubmit?.(input);
      }
    },
    [input, onChange],
  );

  return (
    <FormControl fullWidth sx={styles.root}>
      <IconButton aria-label="search" sx={styles.button} type="submit">
        <SearchIcon />
      </IconButton>

      <TextField
        fullWidth
        sx={{ ...sx, ...styles.input } as SxProps}
        value={input}
        variant={variant ?? "outlined"}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
    </FormControl>
  );
};

export default memo(SearchBar);
