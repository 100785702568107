import { memo, useCallback, useMemo, useState } from "react";

import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { Button, Stack, SxProps } from "@mui/material";

import TextField from "@/components/TextField";
import emoticons from "@/components/TextField/emoticons";

import PoemLibraryDialog from "@/extensions/obituary/components/PoemLibraryDialog";
import {
  DEBOUNCE,
  Editable,
  Item,
} from "@/extensions/obituary/pages/detail/Story/shared";
import { Poem } from "@/extensions/obituary/types";

import { useStory } from "../context";

import EditableItem from "./EditableItem";

import * as object from "@/utils/object";
import { Nullish, Option } from "@/utils/types";

const styles: Record<string, SxProps> = {
  button: {
    justifyContent: "flex-start",
    gap: 0.5,
  },
};

export interface Multiline extends Item {
  type: "multiline";
  variant: "greeting" | "epitaph" | "poem" | "funeral" | "ending";
  text: Nullish<string>;
}

export function isMultiline(item: Item): item is Multiline {
  return item.type === "multiline";
}

export const variants: Option[] = [
  { value: "greeting", label: "Hälsning" },
  { value: "epitaph", label: "Epitafium" },
  { value: "poem", label: "Vers" },
  { value: "funeral", label: "Begravningsinformation" },
  { value: "farewell", label: "Avslut" },
];

export function isVariant(value: unknown): value is Multiline["variant"] {
  return variants.some((variant) => variant.value === value);
}

const Edit: Editable<Multiline> = ({
  item: { hidden, text, variant },
  path,
}) => {
  const { change } = useStory();

  // Used to reset the uncontrolled text field when a poem is selected.
  const [textFieldKey, setTextFieldKey] = useState(0);
  const [openPoemDialog, setOpenPoemDialog] = useState(false);

  const isPoem = variant === "poem";

  const title = useMemo(() => {
    let title =
      variants.find((option) => option.value === variant)?.label ?? "Fritext";
    if (hidden) title = `${title} (Dold)`;
    return title;
  }, [hidden, variant]);

  /// Callbacks

  const handleChangeText = useCallback(
    (text: string) => change<Multiline>(path, { text }),
    [change],
  );

  const handleOpenPoemDialog = useCallback(() => setOpenPoemDialog(true), []);
  const handleClosePoemDialog = useCallback(() => setOpenPoemDialog(false), []);

  const handleSelectPoem = useCallback(
    ({ text, author }: Poem) => {
      if (!isPoem) return;

      change<Multiline>(path, object.omitNullish({ text }));

      // We have to find and set the author manually, as it is rendered by another component.
      // XXX: _Massive_ assumption, requires the author to be the next item in the container.
      //      This _will_ break if you change the `template` structure.
      if (author != null) {
        const tail = path.length - 1;
        const authorPath = [...path];

        if (typeof authorPath[tail] === "number") {
          authorPath[tail] += 1;
          change<Multiline>(authorPath, { text: author });
        } else {
          console.error("Invalid path to author", authorPath);
        }
      }

      setTextFieldKey((prev) => prev + 1);
      handleClosePoemDialog();
    },
    [change],
  );

  return (
    <EditableItem hidden={hidden} path={path} title={title}>
      {!hidden && (
        <Stack gap={1}>
          <TextField
            key={textFieldKey}
            closeEmoticonPickerOnSelect
            multiline
            debounce={DEBOUNCE}
            disabled={hidden}
            emoticons={emoticons}
            minRows={isPoem ? 5 : 3}
            name="text"
            value={text ?? ""}
            variant="outlined"
            onChange={handleChangeText}
          />

          {isPoem && (
            <Button
              disableRipple
              startIcon={<CollectionsBookmarkIcon />}
              sx={styles.button}
              onClick={handleOpenPoemDialog}
            >
              Välj vers från bibliotek
            </Button>
          )}
        </Stack>
      )}

      {isPoem && openPoemDialog && (
        <PoemLibraryDialog
          open={openPoemDialog}
          onClose={handleClosePoemDialog}
          onSubmit={handleSelectPoem}
        />
      )}
    </EditableItem>
  );
};

export default memo(Edit);
