import { memo, useCallback, useMemo, useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";

import dayjs from "dayjs";
import { useSnackbar } from "notistack";

import LabeledSpinner from "@/components/LabeledSpinner";

import AdList from "@/extensions/obituary/components/AdList";
import getAdsFromFormData from "@/extensions/obituary/components/AdList/getAdsFromFormData";
import { Ad } from "@/extensions/obituary/types";

const CONFIRM_UNAPPROVED =
  "En eller flera av de valda annonserna är inte godkända av kund. Vill du fortsätta?";

export interface PublishDialogProps extends DialogProps {
  ads: Ad[];
  onSubmit?: () => void;
  onClose: () => void;
  open: boolean;
}

const PublishDialog: React.FC<PublishDialogProps> = ({
  ads,
  onSubmit,
  onClose,
  open,
}) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSelectable = useCallback(
    (ad: Ad) =>
      ad.publication_date != null ||
      dayjs(ad.publication_date).isAfter(dayjs()),
    [ads],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const obituary_ad_ids = getAdsFromFormData(formData);

      if (obituary_ad_ids.length === 0) {
        enqueueSnackbar("Inga annonser valda.", { variant: "error" });
        return;
      }

      const hasUnapprovedAds = ads.some(
        (ad) => obituary_ad_ids.includes(ad.id) && ad.approved_at == null,
      );

      if (hasUnapprovedAds && !confirm(CONFIRM_UNAPPROVED)) {
        return;
      }

      setIsSubmitting(true);
      const action = api.operations["obituary.ad:publish"];
      if (action == null)
        throw new Error('Invalid action "obituary.ad:publish".');

      const responses = await Promise.allSettled(
        obituary_ad_ids.map((id) =>
          action.call({ params: { obituary_ad_id: id } }),
        ),
      );

      for (const response of responses) {
        if (response.status === "rejected") {
          console.error(response.reason);
          enqueueSnackbar("Något gick fel inskickande av publikation.", {
            variant: "error",
          });

          setIsSubmitting(false);
          return;
        } else {
          if (response.value.status === 400) {
            const data = await response.value.json();
            console.error(data?.["detail"] ?? "Kunde inte hämta felmeddelande");
            enqueueSnackbar("Något gick fel vid publikation.", {
              variant: "error",
            });

            setIsSubmitting(false);
            return;
          }
        }
      }

      enqueueSnackbar("Annonserna har skickats in för bokning.", {
        variant: "success",
      });

      onSubmit?.();
      setIsSubmitting(false);
    },
    [onSubmit],
  );

  const unpublishedAds = useMemo(
    () => ads.filter((ad) => ad.status === "draft" || ad.status === "approved"),
    [ads],
  );

  return (
    <Dialog fullWidth open={open} scroll="paper" onClose={onClose}>
      {isSubmitting ? (
        <LabeledSpinner
          label="Skickar in annonsbokningar…"
          sx={{ height: "100%", minHeight: 420 }}
        />
      ) : (
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle>Boka annonser</DialogTitle>

          <DialogContent>
            <Stack gap={4}>
              <AdList
                showAdMeta
                showWarnings
                ads={unpublishedAds}
                gap={4}
                selectablePred={isSelectable}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Avbryt</Button>
            <Button type="submit">Boka</Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default memo(PublishDialog);
