import * as Bananas from "bananas-commerce-admin";

import { Case } from "@/extensions/klarahill/types";

import * as format from "@/utils/format";

export interface MainContactCardProps extends Bananas.CardProps {
  case: Case;
}

export const MainContactCard: React.FC<MainContactCardProps> = ({
  case: { customers },
  ...props
}) => {
  const mainContact = customers?.find(({ is_main_contact }) => is_main_contact);

  return mainContact == null ? null : (
    <Bananas.Card {...props}>
      <Bananas.CardHeader title="Anhörig" />
      <Bananas.CardContent>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            formName="fullName"
            label="Namn"
            value={
              mainContact?.first_name &&
              `${mainContact?.first_name} ${mainContact?.last_name}`
            }
          />
        </Bananas.CardRow>
        <Bananas.CardRow>
          <Bananas.CardFieldText
            formName="email"
            label="E–post"
            value={mainContact?.email}
          />
          <Bananas.CardFieldText
            formName="phone"
            label="Telefon"
            value={String(format.swedishPhoneNumber(mainContact?.phone))}
          />
        </Bananas.CardRow>
      </Bananas.CardContent>
    </Bananas.Card>
  );
};
