import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import * as Bananas from "bananas-commerce-admin";

import { Button, TableBody } from "@mui/material";

import { useSnackbar } from "notistack";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import { OrderRow } from "@/extensions/klarahill/components/OrderRow";
import SearchBar from "@/extensions/klarahill/components/SearchBar";
import { Order, PaginationResult } from "@/extensions/klarahill/types";

const OrderListPage: Bananas.types.PageComponent<PaginationResult<Order>> = ({
  data,
  refresh,
}) => {
  const api = Bananas.useApi();
  const openDialog = Bananas.useDialog();
  const { navigate } = Bananas.useRouter();
  const { user } = Bananas.useUser();

  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCancelOrder = useCallback(
    async (id: number) => {
      const action = api.operations["order.contrib:cancel-order"];
      if (!action)
        throw new Error('Invalid action "order.contrib:cancel-order".');

      if (
        await openDialog(
          "Annullera order",
          "Är du säker på att du vill annullera denna order.",
          {
            ok: "Ja",
            cancel: "Avbryt",
            dialogProps: { fullWidth: false, maxWidth: "sm" },
          },
        )
      ) {
        try {
          const response = await action.call({
            params: { order_id: id },
          });

          if (response.ok) {
            enqueueSnackbar("Order annullerad.");
            refresh();
          } else {
            enqueueSnackbar("Det gick inte att annullera ordern.", {
              variant: "error",
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [api, enqueueSnackbar, openDialog],
  );

  return (
    <Bananas.Page>
      <Bananas.ContentWrapperWithActionBar>
        <Bananas.Header sx={{ py: 1 }} variant="opaque">
          <Bananas.TitleBar title="Blomsterordrar">
            <SearchBar
              defaultValue={searchParams.get("search") ?? ""}
              placeholder="Sök på namn"
              onChange={(value) =>
                setSearchParams((sp) => ({
                  ...Object.fromEntries(sp.entries()),
                  search: value,
                }))
              }
            />
          </Bananas.TitleBar>
        </Bananas.Header>

        <Bananas.Content layout="fullWidth">
          {data.count !== 0 ? (
            <>
              <Bananas.TableCard>
                <Bananas.Table pagination count={data.count}>
                  <Bananas.TableHead>
                    <Bananas.TableHeading width={60}>Typ</Bananas.TableHeading>
                    <Bananas.TableHeading>Namn</Bananas.TableHeading>
                    <Bananas.TableHeading>Epost</Bananas.TableHeading>
                    <Bananas.TableHeading>
                      Begravningsärende
                    </Bananas.TableHeading>
                    <Bananas.TableHeading>Bekräftat den</Bananas.TableHeading>
                    <Bananas.TableHeading align="right">
                      Ordernummer
                    </Bananas.TableHeading>
                    {user?.is_superuser && (
                      <Bananas.TableHeading align="right">
                        Annullera
                      </Bananas.TableHeading>
                    )}
                  </Bananas.TableHead>

                  <TableBody>
                    {data.results?.map((order, i) => (
                      <OrderRow
                        key={i}
                        handleCancelOrder={handleCancelOrder}
                        isSuperUser={user?.is_superuser ?? false}
                        order={order}
                      />
                    ))}
                  </TableBody>
                </Bananas.Table>
              </Bananas.TableCard>
            </>
          ) : (
            <ErrorCard errorMessage="Det gick inte att hämta några Blomsterordrar. Antingen så saknar din organisation ordrar, eller så har något blivit fel. Kontakta support om felet kvarstår." />
          )}
        </Bananas.Content>

        {user?.is_superuser && (
          <Bananas.ActionBar>
            <Button
              variant="outlined"
              onClick={() => navigate(`report.order:list`)}
            >
              Exportera rapport
            </Button>
          </Bananas.ActionBar>
        )}
      </Bananas.ContentWrapperWithActionBar>
    </Bananas.Page>
  );
};

export default OrderListPage;
