import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import { useSnackbar } from "notistack";

import { Case } from "@/extensions/klarahill/types";
import { ObituaryImage } from "@/extensions/obituary/types";

import { Nullish } from "@/utils/types";

const useImagesQuery = (caseId: Nullish<Case["id"]>) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const images = useQuery({
    queryKey: ["obituary", "images", caseId],
    queryFn: async () => {
      if (caseId == null) return null;

      const action = api.operations["obituary.image:list"];
      if (!action) throw new Error('Invalid action "obituary.image:list".');

      const response = await action.call({
        params: { case_id: caseId },
      });

      if (response.ok) {
        const images: ObituaryImage[] = await response.json();
        return images;
      } else {
        enqueueSnackbar("Kunde inte hämta uppladdade symboler.", {
          variant: "error",
        });
        console.error(response.statusText);
        return null;
      }
    },
  });

  return images;
};

export default useImagesQuery;
