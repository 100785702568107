import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import { useSnackbar } from "notistack";

import { SymbolOptionResponse } from "@/extensions/obituary/types";

const useImagesQuery = () => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const options = useQuery({
    queryKey: ["obituary", "symbols", "options"],
    queryFn: async () => {
      const action = api.operations["obituary.symbol:options"];
      if (!action) throw new Error('Invalid action "obituary.symbol:options".');

      const response = await action.call();

      if (response.ok) {
        const symbols: SymbolOptionResponse = await response.json();
        return symbols;
      } else {
        enqueueSnackbar("Kunde inte hämta symboler.", {
          variant: "error",
        });
        console.error(response.statusText);
        return null;
      }
    },
  });

  return options;
};

export default useImagesQuery;
